var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-page__start" },
    [
      _vm.isAddDevicePath
        ? _c("BackButton", { attrs: { route: "/devices" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--top devices-column-gap",
          class: _vm.isDesktopView ? "" : "devices-column-reverse"
        },
        [
          _c(
            "section",
            { class: _vm.isDesktopView ? "devices-width-50" : "" },
            [
              _c("h2", { staticClass: "gds-font-medium gds-space-stack-ml" }, [
                _vm._v(_vm._s(_vm.isDesktopView ? "Add a Device" : "Devices"))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-l" }, [
                _vm._v(
                  "Connect a Home Battery or EV Charger with GMP. Save energy and money without disrupting your lifestyle. Choose from an option below:"
                )
              ]),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticClass:
                    "gds-flex-container gds-flex-container--column gds-flex-container--top gds-space-stack-l devices-row-gap"
                },
                [
                  _vm.routerLinkShouldShowByod
                    ? _c(
                        "router-link",
                        {
                          staticClass:
                            "gds-button gds-secondary devices-outline-grey devices-padding-m",
                          attrs: { to: "/devices/add-device/byod/" }
                        },
                        [
                          _c("img", {
                            staticClass: "gds-icon gds-space-inline-s",
                            attrs: {
                              src:
                                "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg",
                              alt: "Blue house icon"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "gds-space-inline-m gds-align--text-left"
                            },
                            [_vm._v("Connect a device you own")]
                          ),
                          _vm._v(" "),
                          _c("svg", { staticClass: "gds-icon gds-text-grey" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                              }
                            })
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hyperlinkShouldShowByod
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass:
                              "gds-button gds-secondary devices-outline-grey devices-padding-m",
                            attrs: {
                              href: _vm.txtAddDeviceBYOD,
                              target: "_blank"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "gds-icon gds-space-inline-s",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg",
                                alt: "Blue house icon"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "gds-space-inline-m gds-align--text-left"
                              },
                              [_vm._v("Connect a device you own")]
                            ),
                            _vm._v(" "),
                            _c(
                              "svg",
                              { staticClass: "gds-icon gds-text-grey" },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.routerLinkShouldShowAddNew
                    ? _c(
                        "router-link",
                        {
                          staticClass:
                            "gds-button gds-secondary devices-outline-grey devices-padding-m",
                          attrs: { to: "/devices/add-device/new" }
                        },
                        [
                          _c("img", {
                            staticClass: "gds-icon gds-space-inline-s",
                            attrs: {
                              src:
                                "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg",
                              alt: "White plus sign in blue circle icon"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "gds-space-inline-m gds-align--text-left"
                            },
                            [_vm._v("Get a new device installed")]
                          ),
                          _vm._v(" "),
                          _c("svg", { staticClass: "gds-icon gds-text-grey" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                              }
                            })
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hyperlinkShouldShowAddNew
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass:
                              "gds-button gds-secondary devices-outline-grey devices-padding-m",
                            attrs: {
                              href: _vm.txtAddDeviceNew,
                              target: "_blank"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "gds-icon gds-space-inline-s",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg",
                                alt: "White plus sign in blue circle icon"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "gds-space-inline-m gds-align--text-left"
                              },
                              [_vm._v("Get a new device installed")]
                            ),
                            _vm._v(" "),
                            _c(
                              "svg",
                              { staticClass: "gds-icon gds-text-grey" },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gds-text-grey" }, [
                _vm._v("Not sure or have questions?")
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: _vm.txtAskEnergySpecialist, target: "_blank" }
                },
                [
                  _c("span", { staticClass: "gds-text-grey" }, [
                    _vm._v("Speak with a Home Energy Specialist")
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass:
                "gds-flex-container gds-flex-container--bottom devices-column-gap devices-border-radius devices-background-blue-gradient",
              class: _vm.isDesktopView
                ? "devices-width-50"
                : "gds-space-stack-l devices-width-100"
            },
            [
              _c("img", {
                staticClass: "devices-width-25",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/devices/device-image-ev-charger-emporia.png",
                  alt: "Emporia charger"
                }
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "gds-space-top-l devices-width-40",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/devices/device-image-battery-tesla-powerwall-2.png",
                  alt: "Tesla powerwall"
                }
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "devices-width-25",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/devices/device-image-ev-charger-flo.png",
                  alt: "Flo charger"
                }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }