var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "gds-flex-container gds-flex-container--left gds-space-stack-m devices-gap-1"
    },
    [
      _c(
        "section",
        {
          staticClass:
            "devices-history-card__image-container devices-background-blue-gradient"
        },
        [
          _c("img", {
            staticClass: "devices-history-card__image",
            attrs: {
              src: _vm.device.imageURL,
              alt: _vm.generateAltText(_vm.device)
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "gds-font-medium gds-font-size-l" }, [
          _vm._v(_vm._s(_vm.device.deviceName))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "gds-flex-container gds-flex-container--left devices-gap-1"
          },
          [
            _vm.deviceStatusHistory.overallKey ===
            _vm.STATUS_HISTORY_KEYS.DEVICE_OFFLINE
              ? _c("img", {
                  staticClass: "devices-icon-size-m",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-status-issue.svg",
                    alt: "White exclamation point in orange triangle"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.deviceStatusHistory.overallKey ===
            _vm.STATUS_HISTORY_KEYS.CONNECTION_OK
              ? _c("img", {
                  staticClass: "devices-icon-size-m",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-conection-ok.svg",
                    alt: "White checkmark in green circle"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "gds-font-book gds-font-size-s" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.deviceStatusHistory.overallStatus) +
                  "\n      "
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }