var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.viewModel.FF_Devices
    ? _c(
        "div",
        { staticClass: "gmp-devices-page__device-detail" },
        [
          _c(
            "div",
            { staticClass: "device-detail-grid" },
            [
              _vm.isDesktopView
                ? _c("BackButton", { attrs: { route: "/devices" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.viewModel.device
                ? _c("DeviceDetailSummary", {
                    attrs: { device: _vm.viewModel.device }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.viewModel.loadState
                ? _c("div", { staticClass: "table-loading" })
                : _vm._e(),
              _vm._v(" "),
              _vm.viewModel.loadState
                ? [
                    _vm.viewModel.device &&
                    _vm.viewModel.device.displayEvents &&
                    _vm.viewModel.device.events
                      ? _c("PeakEvents", {
                          attrs: {
                            "energy-events": _vm.viewModel.device.events
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.viewModel.device &&
                    _vm.viewModel.device.stats &&
                    _vm.viewModel.device.stats.length
                      ? _c("ImpactCardGrid", {
                          attrs: {
                            title: "Quick Stats",
                            impacts: _vm.viewModel.device.stats
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.evChargingHistoryShouldShow
                      ? _c("EvChargingHistory", {
                          attrs: {
                            "ev-charging-history-data":
                              _vm.viewModel.evHistoryChargeData
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.batteryChargeLevelShouldShow
                      ? _c("BatteryChargeLevel", {
                          attrs: {
                            "battery-charge-data":
                              _vm.viewModel.batteryChargeData
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.viewModel.device &&
                    _vm.viewModel.device.peakRateSchedule
                      ? _c("PeakRateSchedule", {
                          attrs: {
                            "peak-rate-schedule":
                              _vm.viewModel.device.peakRateSchedule
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.viewModel.deviceInfo
                      ? _c("DeviceInfo", {
                          attrs: { "device-info": _vm.viewModel.deviceInfo }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("DevicesServiceRequestLink")
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("AutoTimer", {
            attrs: {
              "repeat-mode": true,
              "timer-seconds": 60,
              "on-timer-execution": _vm.refreshDeviceDetail
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }