import { FORM_TYPES } from '../../constants';
import formConfigurations from './FormConfigurationsByod.json';
import AddDeviceFormViewModel from '../shared/AddDeviceFormViewModel';

export default class ByodViewModel extends AddDeviceFormViewModel {
  constructor() {
    super();
    this.pageNumberLast = 5;

    Object.assign(this, formConfigurations);

    this.constants = {
      BATTERY_SHARE_PERCENTAGE: 'batterySharePercentage',
    };

    this.lastBatterySharePercentage = undefined;

    this.formData = {
      deviceType: undefined,
      preferredEnrollment: undefined,
      batterySerialNumber: undefined,
      totalBatteryCapacity: undefined,
      installationLocation: undefined,
      installationHasSolar: undefined,
      batteryBrandAndModel: undefined,
      batterySharePercentage: undefined,
      termsAndConditionsConfirmed: undefined,
      estimatedIncentive: '$3,825',
      totalEnrolledCapacity: undefined,
    };

    // Static configurations (directly from JSON)
    this.radioConfigPreferredEnrollment = formConfigurations.radioConfigPreferredEnrollment;
    this.radioButtonConfigDeviceType = formConfigurations.radioButtonConfigDeviceType;
    this.radioButtonConfigInstallationLocation = formConfigurations.radioButtonConfigInstallationLocation;
    this.radioButtonConfigInstallationHasSolar = formConfigurations.radioButtonConfigInstallationHasSolar;
    this.selectConfigBatteryBrand = formConfigurations.selectConfigBatteryBrand;
    this.selectConfigTotalBatteryCapacity = formConfigurations.selectConfigTotalBatteryCapacity;
    this.TextInputConfigBatterySerialNumber = formConfigurations.TextInputConfigBatterySerialNumber;
    this.checkboxConfigTermsAndConditionsConfirmed = formConfigurations.checkboxConfigTermsAndConditionsConfirmed;

    // Header configuration
    this.headerConfig = { ...formConfigurations.headerConfigDefault };

    // Static data related to dead-end brands and models
    this.deadEndBrandAndModels = formConfigurations.deadEndBrandAndModels;

    // Review and submit-related data
    this.primaryResidenceHtml = '';
    this.reviewAndSubmitConfig = {};
  }

  get isBatteryCapacityFieldVisible() {
    return this.formData.batteryBrandAndModel && !this.deadEndBrandAndModel;
  }

  get isBatterySerialNumberVisible() {
    return this.formData.totalBatteryCapacity && !this.deadEndBrandAndModel;
  }

  get isSubmitButtonVisible() {
    return this.pageNumber === this.pageNumberLast && !this.deadEndBrandAndModel;
  }

  get isNextButtonVisible() {
    return this.pageNumber < this.pageNumberLast && !this.deadEndBrandAndModel;
  }

  get deadEndBrandAndModel() {
    return this.deadEndBrandAndModels && this.deadEndBrandAndModels.find(
      (model) => model.value === this.formData.batteryBrandAndModel,
    );
  }

  get isNextButtonDisabled() {
    let pageThreeInvalid = true;
    const data = this.formData;
    switch (this.pageNumber) {
    case 1:
      return !ByodViewModel.isDefined(data.installationHasSolar) || !ByodViewModel.isDefined(data.installationLocation);
    case 2:
      return !ByodViewModel.isDefined(data.batteryBrandAndModel) || !ByodViewModel.isDefined(data.totalBatteryCapacity) || !ByodViewModel.isDefined(data.batterySerialNumber);
    case 3:
      if (ByodViewModel.isDefined(data.preferredEnrollment)) {
        if (data.preferredEnrollment === 'self-powering') {
          pageThreeInvalid = false;
        }

        if (data.preferredEnrollment === 'battery-share'
          && ByodViewModel.isDefined(data.batterySharePercentage)
          && ByodViewModel.isNotNull(data.batterySharePercentage)) {
          pageThreeInvalid = false;
        }
      }

      return pageThreeInvalid;
    case 4:
      return !data.termsAndConditionsConfirmed;
    default:
      return false;
    }
  }

  static isDefined(val) {
    return typeof val !== 'undefined';
  }

  submitForm() {
    console.dir(this.formData);
  }

  updateReviewAndSubmitConfig(currentAccount) {
    this.reviewAndSubmitConfig = this.getReviewAndSubmitConfig(currentAccount);
  }

  onOptionSelectedBatteryBrandAndModel(batteryBrandAndModel) {
    this.formData.batteryBrandAndModel = batteryBrandAndModel;
  }

  onOptionSelectedTotalBatteryCapacity(batteryCapacity) {
    this.formData.totalBatteryCapacity = batteryCapacity;
  }

  getReviewAndSubmitConfig(currentAccount) {
    return {
      formData: this.formData,
      formType: FORM_TYPES.ADD_DEVICE_BYOD,
      primaryResidenceHtml: this.primaryResidenceHtml,
      accountNumber: currentAccount.accountNumber,
      reviewAndSubmitMessage: this.reviewAndSubmitMessage,
    };
  }

  pageNumberWatchHandler(newValue, oldValue, currentAccount) {
    if (newValue !== oldValue) {
      this.updatePageHeader();
      if (newValue === this.pageNumberLast) {
        this.updateReviewAndSubmitConfig(currentAccount);
      }
    }
  }

  formDataWatchHandler(newValue) {
    Object.keys(newValue).forEach((key) => {
      if (key === 'preferredEnrollment') {
        if (newValue[key] === 'self-powering') {
          this.formData.totalEnrolledCapacity = null;
          this.formData[this.constants.BATTERY_SHARE_PERCENTAGE] = null;
        } else if (newValue[key] === 'battery-share') {
          this.formData[this.constants.BATTERY_SHARE_PERCENTAGE] = this.lastBatterySharePercentage;
        }
      }
    });
  }

  updatePageHeader() {
    const config = this.headerConfig;
    switch (this.pageNumber) {
    case 2:
      config.header = 'Connect a home battery';
      config.summary = 'Let’s gather some more information about your device.';
      break;
    case 3:
      config.header = 'Connect a home battery';
      config.summary = 'Decide how your battery will participate during peak events.';
      break;
    case 4:
      config.header = 'Terms & Conditions';
      config.summary = '';
      break;
    case 5:
      config.header = 'Review and Submit';
      config.summary = 'Battery enrollment info';
      break;
    default:
      this.headerConfig = { ...formConfigurations.headerConfigDefault }; // Reset to default
      break;
    }
  }
}
