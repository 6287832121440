var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "devices-action-button-wrapper" }, [
    !_vm.disabled
      ? _c(
          "button",
          {
            staticClass:
              "gds-button gds-secondary devices-action-button devices-border-none devices-color-white",
            class: {
              "gds-loading": _vm.loading,
              "is-custom-secondary": _vm.isCustomSecondary
            },
            on: { click: _vm.buttonClicked }
          },
          [!_vm.loading ? _c("span", [_vm._v(_vm._s(_vm.text))]) : _vm._e()]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.disabled
      ? _c(
          "button",
          {
            staticClass: "gds-button gds-secondary devices-border-none",
            attrs: { disabled: "" }
          },
          [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }