var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eicproduct-container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "eicproduct__page-loader" }, [
            _c("img", {
              staticClass: "eicproduct__page-loader--svg",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Rebates",
              state: "error",
              phonetext: "For further help, please call us at",
              closepath: "/electric-vehicle-charger"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && _vm.isCommercialAccount
        ? _c(
            "div",
            [
              _c("EVChargerNotEligible", {
                attrs: { currentAccount: _vm.currentAccount }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && !_vm.isCommercialAccount
        ? _c("div", [
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "charger-slat-1" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("1")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    !_vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account: " +
                                                _vm._s(_vm.accountNickname)
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account Number: " +
                                                _vm._s(
                                                  _vm.currentAccount
                                                    .accountNumber
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(1)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 1
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 1 && _vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for\n              enrollment in the EV Charger program."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    key: _vm.currentAccount.accountNumber,
                                    staticClass:
                                      "eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct__account-box gds-space-stack-l"
                                      },
                                      [
                                        _vm.currentAccount
                                          ? _c("span", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(1),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("titlecase")(
                                                              _vm.currentAccount
                                                                .lastName +
                                                                ", " +
                                                                _vm
                                                                  .currentAccount
                                                                  .firstName
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(2),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "addressFormat"
                                                            )(
                                                              _vm.currentAccount
                                                                .address,
                                                              {
                                                                separator: ", "
                                                              }
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentAccount
                                                              .accountNumber
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentPage === 1 && !_vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information for your electric\n              vehicle charger."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct__account-box gds-space-stack-l"
                                  },
                                  [
                                    _vm.currentAccount
                                      ? _c("span", [
                                          _c("div", { staticClass: "row-m" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "account-info-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(4),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("titlecase")(
                                                            _vm.currentAccount
                                                              .lastName +
                                                              ", " +
                                                              _vm.currentAccount
                                                                .firstName
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(5),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "addressFormat"
                                                          )(
                                                            _vm.currentAccount
                                                              .address,
                                                            { separator: ", " }
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(6),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentAccount
                                                            .accountNumber
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.accounts.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline accountInformation eicproduct__edit-account-details",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.chooseAccount()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "gds-button gds-text-button choose-account-button"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "eicproduct__edit-icon-inline gds-icon"
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.accounts.length > 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Switch Account"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "charger-slat-2" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("2")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(7)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-m"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productSubmission.vehicles[0]
                                              .brandModel
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(2)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 2
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 2
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "form",
                                  { attrs: { "data-vv-scope": "page2" } },
                                  [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass:
                                          "gds-fieldset gds-space-stack-s"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slap__inner-row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row-s" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproducts-required-text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Your vehicle must be a fully electric vehicle in order to receive a free EV charger.\n                        Plug-in hybrid vehicles do not qualify for a free charger.\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Is your vehicle 100% electric?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-position--relative"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gds-space-stack-ml"
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm
                                                                            .productSubmission
                                                                            .vehicles[0]
                                                                            .isAllElectric,
                                                                        expression:
                                                                          "productSubmission.vehicles[0].isAllElectric"
                                                                      },
                                                                      {
                                                                        name:
                                                                          "validate",
                                                                        rawName:
                                                                          "v-validate",
                                                                        value:
                                                                          "required",
                                                                        expression:
                                                                          "'required'"
                                                                      }
                                                                    ],
                                                                    key:
                                                                      "isAllElectric",
                                                                    staticClass:
                                                                      "eicproduct-input-field__select",
                                                                    attrs: {
                                                                      name:
                                                                        "isAllElectric",
                                                                      autofocus:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      change: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .vehicles[0],
                                                                            "isAllElectric",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onChangeIsAllElectric()
                                                                        }
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          disabled:
                                                                            "",
                                                                          select:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select..."
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "yes"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Yes"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "no"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "No"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.errors.first(
                                                      "isAllElectric",
                                                      "page2"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "validation-error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "isAllElectric",
                                                                  "page2"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slap__inner-row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row-s" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproducts-required-text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Your GMP account address must be listed on your Vermont DMV registration in order to\n                        participate in the EV charger program.\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Is your electric vehicle registered in Vermont?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-ml"
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .vehicles[0]
                                                                        .isRegisteredInVt,
                                                                    expression:
                                                                      "productSubmission.vehicles[0].isRegisteredInVt"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "VTRegistrationRequired",
                                                                staticClass:
                                                                  "eicproduct-input-field__select",
                                                                attrs: {
                                                                  name:
                                                                    "VTRegistrationRequired",
                                                                  autofocus: ""
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .vehicles[0],
                                                                        "isRegisteredInVt",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onChangeRegistration()
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value: "",
                                                                      disabled:
                                                                        "",
                                                                      select: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Select..."
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "yes"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Yes"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "no"
                                                                    }
                                                                  },
                                                                  [_vm._v("No")]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "VTRegistrationRequired",
                                                          "page2"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "VTRegistrationRequired",
                                                                      "page2"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slap__inner-row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row-s" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproducts-required-text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Your home must have an internet connection in order to participate in the EV Charger program.\n                        A mobile hot spot is not sufficient.\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Does your home have a reliable internet\n                          connection?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-position--relative"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gds-space-stack-ml"
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm
                                                                            .productSubmission
                                                                            .programEnrollment
                                                                            .inHomeChargerProgram
                                                                            .hasInternet,
                                                                        expression:
                                                                          "productSubmission.programEnrollment.inHomeChargerProgram.hasInternet"
                                                                      },
                                                                      {
                                                                        name:
                                                                          "validate",
                                                                        rawName:
                                                                          "v-validate",
                                                                        value:
                                                                          "required",
                                                                        expression:
                                                                          "'required'"
                                                                      }
                                                                    ],
                                                                    key:
                                                                      "internetRequired",
                                                                    staticClass:
                                                                      "eicproduct-input-field__select",
                                                                    attrs: {
                                                                      name:
                                                                        "hasInternet",
                                                                      autofocus:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      change: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .programEnrollment
                                                                              .inHomeChargerProgram,
                                                                            "hasInternet",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        _vm.onChangeInternet
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          disabled:
                                                                            "",
                                                                          select:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select..."
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "yes"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Yes"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "no"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "No"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.errors.first(
                                                      "hasInternet",
                                                      "page2"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "validation-error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "hasInternet",
                                                                  "page2"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showInformationalModal
                              ? _c("ModalEICProducts", {
                                  attrs: {
                                    modalName: "informational",
                                    close: _vm.closeModal,
                                    modalMessage: _vm.modalMessage,
                                    modalLink: _vm.modalLink,
                                    modalLinkName: _vm.modalLinkName
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "gds-button gds-compact",
                                on: {
                                  click: function($event) {
                                    return _vm.finishPage("page2", 3)
                                  }
                                }
                              },
                              [_vm._v("\n            Next\n          ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "charger-slat-3" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("3")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(8)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-m"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productSubmission.vehicles[0]
                                              .brandModel
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(3)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 3
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 3
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "eicproduct-slap__inner-row" },
                                  [
                                    _c("div", [
                                      _c(
                                        "form",
                                        { attrs: { "data-vv-scope": "page3" } },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "gds-fieldset gds-space-stack-s"
                                            },
                                            [
                                              _c(
                                                "h1",
                                                {
                                                  staticClass:
                                                    "gds-display-1 gds-space-stack-l"
                                                },
                                                [_vm._v("Vehicle Information")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Vehicle VIN (17 Digits)"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-position--relative"
                                                    },
                                                    [
                                                      _c("the-mask", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              "required|length:17",
                                                            expression:
                                                              "'required|length:17'"
                                                          }
                                                        ],
                                                        key: "VIN",
                                                        ref: "focuser2",
                                                        staticClass:
                                                          "eicproduct-input-field__input gds-input-field__input",
                                                        attrs: {
                                                          mask:
                                                            "XXXXXXXXXXXXXXXXX",
                                                          type: "text",
                                                          name: "VIN",
                                                          id: "vin",
                                                          placeholder:
                                                            "Type here..."
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .vehicles[0]
                                                              .vinNumber,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .vehicles[0],
                                                              "vinNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "productSubmission.vehicles[0].vinNumber"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "VIN",
                                                        "page3"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "VIN",
                                                                    "page3"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row gds-space-stack-ml"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-item margin-right"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field gds-space-stack-l"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Vehicle Make and Model"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0]
                                                                      .brandModel,
                                                                  expression:
                                                                    "productSubmission.vehicles[0].brandModel"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required",
                                                                  expression:
                                                                    "'required'"
                                                                }
                                                              ],
                                                              key:
                                                                "make and model",
                                                              staticClass:
                                                                "eicproduct-input-field-l__select gds-input-field",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disableBrandModel,
                                                                name:
                                                                  "make and model",
                                                                autofocus: ""
                                                              },
                                                              on: {
                                                                change: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal = Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .vehicles[0],
                                                                      "brandModel",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.parseVehicle(
                                                                      _vm
                                                                        .productSubmission
                                                                        .vehicles[0]
                                                                        .brandModel
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    disabled:
                                                                      "",
                                                                    selected: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select Make/Model"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.filteredVehicleModels,
                                                                function(
                                                                  model,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: i,
                                                                      domProps: {
                                                                        value: model
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "formatVehicleModel"
                                                                          )(
                                                                            model
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "make and model",
                                                            "page3"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "make and model",
                                                                        "page3"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "flex-item"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Model Year (YYYY)"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("the-mask", {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required|numeric|min_value: 2000|max_value: " +
                                                                  parseInt(
                                                                    new Date().getFullYear() +
                                                                      5
                                                                  ),
                                                                expression:
                                                                  "`required|numeric|min_value: 2000|max_value: ${parseInt(new Date().getFullYear() + 5)}`"
                                                              }
                                                            ],
                                                            key: "year",
                                                            staticClass:
                                                              "gds-input-field__input gds-space-stack-s",
                                                            attrs: {
                                                              mask: "####",
                                                              name: "year",
                                                              type: "tel",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "YYYY"
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "touched",
                                                                  true
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .year,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "year",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "productSubmission.vehicles[0].year"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "year",
                                                            "page3"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "year",
                                                                        "page3"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("New or Used Vehicle")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-flex-container gds-flex-container--left gds-flex-container--space-between radioContainer"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-radio"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .preOwned,
                                                                expression:
                                                                  "productSubmission.vehicles[0].preOwned"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "new or used"
                                                            },
                                                            domProps: {
                                                              value: false,
                                                              checked: _vm._q(
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .preOwned,
                                                                false
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "preOwned",
                                                                  false
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-radio__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(9)
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-radio"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .preOwned,
                                                                expression:
                                                                  "productSubmission.vehicles[0].preOwned"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "new or used"
                                                            },
                                                            domProps: {
                                                              value: true,
                                                              checked: _vm._q(
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .preOwned,
                                                                true
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0],
                                                                  "preOwned",
                                                                  true
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-radio__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(10)
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "new or used",
                                                    "page3"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "new or used",
                                                                "page3"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row-l gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-item margin-right gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Purchase Date"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "eicproduct-purchasedate__date-box"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "my-account-billing-report__filtration-date-icon",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$refs.datePicker.showCalendar()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg",
                                                                    {
                                                                      staticClass:
                                                                        "gds-icon"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "use",
                                                                        {
                                                                          attrs: {
                                                                            "xlink:href":
                                                                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("datepicker", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "date of purchase",
                                                                ref:
                                                                  "datePicker",
                                                                attrs: {
                                                                  name:
                                                                    "date of purchase",
                                                                  format:
                                                                    "MMMM d, yyyy",
                                                                  typeable: true,
                                                                  placeholder:
                                                                    "Select Date...",
                                                                  "disabled-dates":
                                                                    _vm.disableFutureDates,
                                                                  "input-class":
                                                                    "export-data__date my-account-billing-report__filtration-date-text"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0]
                                                                      .dateOfPurchase,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .vehicles[0],
                                                                      "dateOfPurchase",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "productSubmission.vehicles[0].dateOfPurchase"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "date of purchase",
                                                            "page3"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "date of purchase",
                                                                        "page3"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "flex-item",
                                                      attrs: {
                                                        id: "dealership"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                'Dealership (If private sale, enter "Private\n                            Sale")'
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .vehicles[0]
                                                                    .dealerName,
                                                                expression:
                                                                  "productSubmission.vehicles[0].dealerName"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            key: "dealership",
                                                            staticClass:
                                                              "gds-input-field__input gds-space-stack-s",
                                                            attrs: {
                                                              name:
                                                                "dealership",
                                                              type: "text",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "Type Here..."
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .vehicles[0]
                                                                  .dealerName
                                                            },
                                                            on: {
                                                              keyup: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectDealership(
                                                                  $event
                                                                )
                                                              },
                                                              input: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .vehicles[0],
                                                                    "dealerName",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  _vm.$emit(
                                                                    "touched",
                                                                    true
                                                                  )
                                                                  _vm.filterDealerships()
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.filterDealerships()
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "dealership",
                                                            "page3"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "dealership",
                                                                        "page3"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.filteredDealerships
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "typeAhead-container"
                                                            },
                                                            _vm._l(
                                                              _vm.filteredDealerships,
                                                              function(dealer) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      dealer.id,
                                                                    staticClass:
                                                                      "typeAhead-card gds-space-stack-m",
                                                                    class: {
                                                                      "active-dealership typeAhead-card gds-space-stack-m":
                                                                        _vm.currentDealership ===
                                                                        dealer.id
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.setDealership(
                                                                          dealer.name
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "typeAhead-text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            dealer.name
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page3", 4)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "charger-slat-4" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("4")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-slat__title-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__title gds-slat__title"
                                  },
                                  [
                                    _vm._v(
                                      "\n                Upload Documents\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.selectedFile
                                  ? _c("modal-document-checklist", {
                                      attrs: {
                                        uploadDocumentList:
                                          _vm.uploadDocumentList,
                                        visible: _vm.showDocumentChecklist,
                                        select: _vm.onSelect,
                                        save: _vm.onSave,
                                        selectedFile: _vm.selectedFile,
                                        close: _vm.closeModal,
                                        documentTypes: _vm.documentTypes
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFileLoadTooBigModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "fileLoadTooBig",
                                        close: _vm.closeModal,
                                        modalMessage: _vm.modalMessage
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showMissingDocumentsModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "missingDocument",
                                        close: _vm.closeModal,
                                        modalTitle:
                                          "You’re missing a required document",
                                        modalMessage:
                                          "Please upload a copy of the following:",
                                        listItems: _vm.missingDocuments
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                eicproduct-slat__header-summary\n                gds-flex-container gds-flex-container\n              "
                                  },
                                  [
                                    _vm.documents && _vm.documents.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  this.documents.length +
                                                    " Files Uploaded"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.documents && _vm.documents.length === 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  this.documents.length +
                                                    " File Uploaded"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "\n                  eicproduct-slat__header-edit-button\n                  gds-button gds-round gds-secondary\n                ",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(4)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Edit\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 4
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 4
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "slat-title-wrapper" },
                                      [
                                        _c("form", [
                                          _c(
                                            "div",
                                            { attrs: { id: "fileUploader" } },
                                            [
                                              _c("File-Uploader", {
                                                attrs: {
                                                  uploadDocumentList:
                                                    _vm.uploadDocumentList,
                                                  docTypes: _vm.documentTypes
                                                },
                                                on: {
                                                  "file-list-changed":
                                                    _vm.updateFileList
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page4", 5)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "charger-slat-5" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("5")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(11)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "gds-body-normal gds-space-inline-m"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(5)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 5
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 5
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "form",
                                  { attrs: { "data-vv-scope": "page5" } },
                                  [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass:
                                          "gds-fieldset gds-space-stack-s"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slap__inner-row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row-s" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproducts-required-text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        In order to identify how to best connect your EV charger to the internet, we have a few more\n                        questions.\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Do you have a WiFi (wireless) network in your\n                          home?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-ml"
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .inHomeChargerProgram
                                                                        .hasWifiNetwork,
                                                                    expression:
                                                                      "productSubmission.programEnrollment.inHomeChargerProgram.hasWifiNetwork"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "wifiNetworkRequired",
                                                                staticClass:
                                                                  "eicproduct-input-field__select",
                                                                attrs: {
                                                                  name:
                                                                    "wifiNetworkRequired",
                                                                  autofocus: ""
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .inHomeChargerProgram,
                                                                        "hasWifiNetwork",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onChangeWifiNetwork(
                                                                        $event
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value: "",
                                                                      disabled:
                                                                        "",
                                                                      select: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Select..."
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "yes"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Yes"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "no"
                                                                    }
                                                                  },
                                                                  [_vm._v("No")]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "wifiNetworkRequired",
                                                          "page5"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "wifiNetworkRequired",
                                                                      "page5"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slap__inner-row"
                                          },
                                          [
                                            _vm.productSubmission
                                              .programEnrollment
                                              .inHomeChargerProgram
                                              .hasWifiNetwork === "yes"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row-s" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-m"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "eicproducts-required-text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        The WiFi signal must strong enough to reach the location where your charger will be\n                        installed/located.\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Is there a strong, reliable WiFi signal where the EV\n                          charger will be located?"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gds-position--relative"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "gds-space-stack-ml"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              _vm
                                                                                .productSubmission
                                                                                .programEnrollment
                                                                                .inHomeChargerProgram
                                                                                .hasWifiSignalAtCharger,
                                                                            expression:
                                                                              "productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger"
                                                                          },
                                                                          {
                                                                            name:
                                                                              "validate",
                                                                            rawName:
                                                                              "v-validate",
                                                                            value:
                                                                              "required",
                                                                            expression:
                                                                              "'required'"
                                                                          }
                                                                        ],
                                                                        key:
                                                                          "wifiSignalRequired",
                                                                        staticClass:
                                                                          "eicproduct-input-field__select",
                                                                        attrs: {
                                                                          name:
                                                                            "wifiSignalRequired",
                                                                          autofocus:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              _vm
                                                                                .productSubmission
                                                                                .programEnrollment
                                                                                .inHomeChargerProgram,
                                                                              "hasWifiSignalAtCharger",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "",
                                                                              disabled:
                                                                                "",
                                                                              select:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Select..."
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "yes"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Yes"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "no"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "No"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "wifiSignalRequired",
                                                          "page5"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "wifiSignalRequired",
                                                                      "page5"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showInformationalModal
                              ? _c(
                                  "div",
                                  [
                                    _c("modal-informational", {
                                      attrs: {
                                        modalType: "informational",
                                        modalMessage: _vm.modalMessage,
                                        modalLink: _vm.modalLink
                                      },
                                      on: { "close-modal": _vm.closeModal }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "gds-button gds-compact",
                                on: {
                                  click: function($event) {
                                    return _vm.finishPage("page5", 6)
                                  }
                                }
                              },
                              [_vm._v("Next")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "charger-slat-6" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("6")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(12)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-s"
                                      },
                                      [
                                        _vm._v(
                                          "Mailing Address: " +
                                            _vm._s(
                                              _vm._f("addressFormat")(
                                                _vm.productSubmission.account
                                                  .mailingAddress,
                                                { seperator: ", " }
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(6)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 6
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 6
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "form",
                                      { attrs: { "data-vv-scope": "page6" } },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "eicproduct-title"
                                                },
                                                [
                                                  _vm._v(
                                                    "How should we contact you if we need additional info to complete your\n                      enrollment in the EV Charger program?"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Email Address")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .emailAddress,
                                                        expression:
                                                          "productSubmission.emailAddress"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required|email",
                                                        expression:
                                                          "'required|email'"
                                                      }
                                                    ],
                                                    key: "email address",
                                                    ref: "email",
                                                    staticClass:
                                                      "eicproduct-input-field gds-input-field__input",
                                                    attrs: {
                                                      name: "email address",
                                                      type: "email",
                                                      placeholder: "Your email",
                                                      autocomplete: "email"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .emailAddress
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.productSubmission,
                                                            "emailAddress",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "email address",
                                                    "page6"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "email address",
                                                                "page6"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { staticClass: "gds-input-field" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("Phone Number")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-xl"
                                                },
                                                [
                                                  _c("the-mask", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          "required|min:10",
                                                        expression:
                                                          "'required|min:10'"
                                                      }
                                                    ],
                                                    key: "phone number",
                                                    staticClass:
                                                      "eicproduct-input-field gds-input-field__input",
                                                    attrs: {
                                                      mask: "+1 (###) ###-####",
                                                      "data-vv-validate-on":
                                                        "input",
                                                      name: "phone number",
                                                      type: "tel",
                                                      placeholder:
                                                        "Your phone number"
                                                    },
                                                    nativeOn: {
                                                      input: function($event) {
                                                        _vm.touched = true
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.productSubmission
                                                          .contactNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.productSubmission,
                                                          "contactNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productSubmission.contactNumber"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "phone number",
                                                    "page6"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "phone number",
                                                                "page6"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.isAlreadyEbillEnrolled
                                            ? _c("div", [
                                                _vm._m(13),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-body-small gds-space-stack-l slat3-body"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      You can reduce your carbon footprint even more,\n                      by switching to paperless billing! Paperless monthly energy\n                      statements cut carbon and clutter while saving trees.\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-ml"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-checkbox gds-checkbox-thin"
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll,
                                                              expression:
                                                                "productSubmission.programEnrollment.ebill.ebillEnroll"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                            name: ""
                                                          },
                                                          domProps: {
                                                            checked: Array.isArray(
                                                              _vm
                                                                .productSubmission
                                                                .programEnrollment
                                                                .ebill
                                                                .ebillEnroll
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                  null
                                                                ) > -1
                                                              : _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEnroll",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEnroll",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill,
                                                                  "ebillEnroll",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          staticClass:
                                                            "gds-checkbox__faux"
                                                        }),
                                                        _vm._v(" "),
                                                        _vm._m(14)
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.productSubmission
                                                  .programEnrollment.ebill
                                                  .ebillEnroll
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-ml"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Account email address"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill
                                                                      .ebillEmailAddress,
                                                                  expression:
                                                                    "productSubmission.programEnrollment.ebill.ebillEmailAddress"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required|email",
                                                                  expression:
                                                                    "'required|email'"
                                                                }
                                                              ],
                                                              key:
                                                                "account email address",
                                                              ref: "email",
                                                              staticClass:
                                                                "eicproduct-input-field gds-input-field__input",
                                                              attrs: {
                                                                name:
                                                                  "account email address",
                                                                type: "email",
                                                                placeholder:
                                                                  "Your email",
                                                                autocomplete:
                                                                  "email"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEmailAddress
                                                              },
                                                              on: {
                                                                input: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEmailAddress",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "touched",
                                                                      true
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "account email address",
                                                              "page6"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "account email address",
                                                                          "page6"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br")
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "`eicproduct__method-next-btn gds-button gds-compact`",
                                            attrs: {
                                              type: "submit",
                                              disabled: _vm.pendingSubmit
                                            },
                                            on: { click: _vm.handleSubmit }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Submit Request\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button [modifier class] gds-loading"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text"
                                              },
                                              [_vm._v("Submit Request")]
                                            ),
                                            _vm._v(" "),
                                            _c("figure", {
                                              staticClass: "gds-button__icon"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Customer Information")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Verify Program Requirements")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Vehicle Details")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-radio__label" }, [
      _c("b", [_vm._v("New Vehicle")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-radio__label" }, [
      _c("b", [_vm._v("Used Vehicle")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("EV Charger Communication")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Contact Info & Submit")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-body-small gds-space-stack-l" }, [
      _c("span", { staticClass: "gds-display-1 gds-space-stack-s" }, [
        _vm._v("Would you like to go paperless?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-font-size-ml" }, [
      _c("b", [_vm._v("Yes!")]),
      _vm._v(
        " - Enroll me in paperless billing so I can reduce my carbon footprint even more.\n                        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }