var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", { staticClass: "gds-space-stack-l" }, [
      _c("h4", { staticClass: "gds-font-medium gds-space-stack-ml" }, [
        _vm._v("Peak Events")
      ]),
      _vm._v(" "),
      _vm.energyEvents && !_vm.energyEvents.length
        ? _c(
            "div",
            { staticClass: "gds-font-book gds-font-size-m gds-text-grey" },
            [_vm._v("There are no peak events scheduled for this device.")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.energyEvents && _vm.energyEvents.length
      ? _c(
          "div",
          _vm._l(_vm.energyEvents, function(event, index) {
            return _c(
              "section",
              {
                key: event.id,
                staticClass:
                  "gds-flex-container gds-flex-container--top gds-flex-container--left",
                class:
                  index !== _vm.energyEvents.length - 1
                    ? "gds-space-stack-l"
                    : ""
              },
              [
                !event.expired
                  ? [
                      !event.optedOut
                        ? _c("img", {
                            staticClass: "gds-space-inline-m",
                            attrs: {
                              src:
                                "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-lg.svg",
                              alt: "Purple leaf icon"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      event.optedOut
                        ? _c("img", {
                            staticClass: "gds-space-inline-m",
                            attrs: {
                              src:
                                "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-opt-out-lg.svg",
                              alt: "Purple leaf with minus sign icon"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "gds-font-demi" }, [
                          _vm._v(
                            _vm._s(
                              event.inProgress
                                ? "Energy Event In Progress"
                                : "Energy Event Scheduled"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        event.dateRange
                          ? _c("span", { staticClass: "gds-font-book" }, [
                              _vm._v(_vm._s(event.dateRange))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        event.canOptOut
                          ? _c("a", { attrs: { href: "/account/devices" } }, [
                              _c("span", { staticClass: "gds-text-black" }, [
                                _vm._v("Manage participation.")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "gds-flex-container gds-flex-container--left gds-space-top-m"
                          },
                          [
                            event.optedOut
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "gds-font-demi gds-space-inline-m devices-border-grey devices-container-compact"
                                  },
                                  [_vm._v("OPTED OUT")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            event.inProgress
                              ? _c(
                                  "span",
                                  { staticClass: "gds-flex-container" },
                                  [
                                    _c("event-in-progress-indicator"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(event.timeRemaining))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }