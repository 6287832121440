<template>
  <div>
    <h4 v-if="title" class="gds-font-medium gds-space-stack-ml">{{ title }}</h4>
    <div class="impact-card-list">
      <ImpactCard
        v-for="impact of impacts"
        :key="impact.key"
        :impact="impact"
      />
    </div>
  </div>
</template>

<script>

import ImpactCard from './ImpactCard.vue';

export default {
  name: 'ImpactCardGrid',
  components: {
    ImpactCard,
  },
  props: {
    impacts: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
