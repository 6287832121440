<template>
  <div class="gds-space-stack-l">
    <div class="gds-font-demi gds-font-size-xl gds-space-stack-m">
      {{ config.title || '' }}
    </div>
    <DevicesSelectControl
      :config="config"
      :option-selected="optionSelected"
    />
  </div>
</template>

<script>
import DevicesSelectControl from '../DevicesSelectControl.vue';

export default {
  name: 'SelectControlWithTitle',
  components: {
    DevicesSelectControl,
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    optionSelected: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
};
</script>
