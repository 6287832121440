var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gds-space-stack-l" },
    [
      _c("DeviceStatusHistoryHeader", {
        attrs: {
          device: _vm.device,
          "device-status-history": _vm.deviceStatusHistory
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--left gds-flex-container--stretch devices-gap-1 devices-horizontal-scroll"
        },
        _vm._l(_vm.deviceStatusHistory.history || [], function(
          dailyStatus,
          index
        ) {
          return _c("DeviceDailyStatusCard", {
            key: index,
            attrs: { status: dailyStatus }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }