export const proofOfPurchase = 'proofOfPurchase';
export const w9 = 'w9';
export const vehicleRegistration = 'vehicleRegistration';
export const customerRebateForm = 'customerRebateForm';
export const electricalQuote = 'electricalQuote';
export const statePreApproval = 'statePreApproval';


export const maxFileLoadSize = 31457280;
export const eBikeRebateAmount = 200;
export const inductionCooktopIncentiveAmount = 200;

export const regulatoryRebateSharingText1 = `
  Green Mountain Power’s rebates and incentives are through Vermont legislation that supports carbon emission and
  fossil fuel reduction by Vermont’s distribution utilities in partnership with customers.&nbsp; By participating in this
  rebate program, you consent to GMP sharing your personal information and details of your rebate with the State of Vermont’s
  Department of Public Service (DPS). Any sharing of customer information with the DPS will be done under a non-disclosure
  agreement. Please know you may be contacted directly by the DPS or a third-party consultant contracted by the DPS to conduct
  studies on how these incentive programs helped you.
`;

export const regulatoryRebateSharingText2 = `
  Any sharing of customer information with the DPS will be done under a non-disclosure
  agreement. Please know you may be contacted directly by the DPS or a third-party consultant contracted by the DPS to conduct
  studies on how these incentive programs helped you.
`;

export const STATE_LIST = ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"];
export const PROVINCE_LIST = ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"];
export const COUNTY_LIST = ["Addison", "Bennington", "Caledonia", "Chittenden", "Essex", "Grand Isle", "Lamoile", "Orange", "Orleans", "Rutland", "St Johnsbury", "Washington", "Windsor", "Windham"];
export const heatPumpBtuSizes =
 [
  { heatPumpType: 'Single-Head', size: '6,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '12,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '15,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '18,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '24,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '30,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '36,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '42,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '48,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '60,000',enabled: true },
  { heatPumpType: 'Single-Head', size: '72,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '6,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '12,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '15,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '18,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '24,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '30,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '36,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '42,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '48,000',enabled: true },
  { heatPumpType: 'Multi-Head', size: '60,000', enabled: true },
  { heatPumpType: 'Multi-Head', size: '72,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '6,000', enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '12,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '15,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '18,000', enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '24,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '30,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '36,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '42,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '48,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '60,000',enabled: true },
  { heatPumpType: 'Centrally Ducted', size: '72,000',enabled: true },
];
