<template>
  <div class="gds-flex-container gds-flex-container--left gds-space-stack-m devices-gap-1">
    <section class="devices-history-card__image-container devices-background-blue-gradient">
      <img
        :src="device.imageURL"
        :alt="generateAltText(device)"
        class="devices-history-card__image"
      >
    </section>
    <div>
      <div class="gds-font-medium gds-font-size-l">{{ device.deviceName }}</div>
      <div class="gds-flex-container gds-flex-container--left devices-gap-1">
        <img
          v-if="deviceStatusHistory.overallKey === STATUS_HISTORY_KEYS.DEVICE_OFFLINE"
          class="devices-icon-size-m"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-status-issue.svg"
          alt="White exclamation point in orange triangle"
        >
        <img
          v-if="deviceStatusHistory.overallKey === STATUS_HISTORY_KEYS.CONNECTION_OK"
          class="devices-icon-size-m"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-conection-ok.svg"
          alt="White checkmark in green circle"
        >
        <span class="gds-font-book gds-font-size-s">
          {{ deviceStatusHistory.overallStatus }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS_HISTORY_KEYS } from '../../../constants';
import DevicesMixin from '../../../../../mixins/DevicesMixin';

export default {
  name: 'DeviceStatusHistoryHeader',
  mixins: [
    DevicesMixin,
  ],
  props: {
    deviceStatusHistory: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    STATUS_HISTORY_KEYS() {
      return STATUS_HISTORY_KEYS;
    },
  },
};
</script>
