export default class FormViewModel {
  constructor() {
    this.pageNumber = 1;
    this.pendingSave = false;
  }

  onBackButtonClicked() {
    this.pageNumber -= 1;
  }

  onNextButtonClicked() {
    this.pageNumber += 1;
  }

  onSubmitButtonClicked() {
    this.pendingSave = true;
  }

  get isPageOneVisible() {
    return this.pageNumber === 1;
  }

  get isPageTwoVisible() {
    return this.pageNumber === 2;
  }

  get isPageThreeVisible() {
    return this.pageNumber === 3;
  }

  get isPageFourVisible() {
    return this.pageNumber === 4;
  }

  get isPageFiveVisible() {
    return this.pageNumber === this.pageNumberLast;
  }

  get isLastPage() {
    return this.pageNumber === this.pageNumberLast;
  }

  get isNextButtonVisible() {
    return this.pageNumber < this.pageNumberLast;
  }

  get isSubmitButtonVisible() {
    return this.pageNumber === this.pageNumberLast;
  }

  static isNotNull(val) {
    return val !== null;
  }

  static isDefined(val) {
    return typeof val !== 'undefined';
  }

  static stringIsNotEmpty(string) {
    return FormViewModel.isDefined(string) && string.length > 0;
  }
}
