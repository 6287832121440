<template>
  <div class="gmp-device-details-summary">
    <section
      class="gds-flex-container gds-flex-container--stretch"
      :class="isDesktopView ? '' : 'gds-flex-container--left gds-flex-container--column'"
    >
      <router-link
        v-if="!isDesktopView"
        to="/devices"
        class="gds-button gds-secondary gds-button-circle button-white-overlay"
      >
        <svg class="gds-icon black">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
        </svg>
      </router-link>
      <div
        :class="isDesktopView
          ? 'gds-flex-container gds-flex-container--bottom gds-space-inline-m devices-background-blue-gradient devices-border-radius devices-width-25 gradient-background'
          : 'gds-flex-container gds-flex-container--bottom devices-background-blue-gradient devices-border-radius devices-width-100 gradient-background'
        "
      >
        <img
          :class="'device-image'"
          :src="imageUrl"
          :alt="generateAltText(device)"
        >
      </div>
      <div class="gds-flex-container gds-flex-container--column gds-flex-container--top gds-flex-container--space-between devices-width-100 devices-padding-m">
        <section
          class="gds-space-stack-m devices-width-100"
          :class="isDesktopView ? '' : 'gds-flex-container gds-flex-container--space-between gds-flex-container--wrap gds-flex-container--top'"
        >
          <div class="gds-space-inline-m">
            <h2 class="gds-font-medium gds-space-stack-m">{{ device.deviceName }}</h2>
            <div class="gds-text-grey gds-font-medium">{{ device.deviceTypeDescription }}</div>
          </div>
          <div v-if="operationalModeMessage" class="gds-space-top-m devices-font-capitalize devices-background-darkgray devices-color-white devices-container-compact border-round">
            {{ operationalModeMessage }}
          </div>
        </section>
        <section
          class="gds-flex-container gds-flex-container--space-between devices-gap-2"
          :class="isDesktopView ? '' : 'gds-flex-container--wrap gds-flex-container--left'"
        >
          <div v-if="powerMessage" class="gds-flex-container">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-power.svg"
              alt="Power button icon"
            >
            <span class="devices-padding-m">
              <div class="gds-font-medium">{{ powerMessage }}</div>
              <div class="gds-text-grey">Power</div>
            </span>
          </div>
          <div v-if="wifiMessage" class="gds-flex-container">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-connection.svg"
              alt="WiFi connection icon"
            >
            <span class="devices-padding-m">
              <div class="gds-font-medium">{{ wifiMessage }}</div>
              <div class="gds-text-grey">Wi-Fi</div>
            </span>
          </div>
          <div v-if="touMessage" class="gds-flex-container">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-rate-status.svg"
              alt="Clock icon"
            >
            <span class="devices-padding-m">
              <div class="gds-font-medium">{{ touMessage }}</div>
              <div class="gds-text-grey">Current Rate Status</div>
            </span>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';
import DevicesMixin from '../../../../mixins/DevicesMixin';

export default {
  name: 'DeviceDetailSummary',
  mixins: [
    MediaQueryMixin,
    DevicesMixin,
  ],
  props: {
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
        || this.deviceIsExtraExtraLarge
        || this.deviceExceedsExtraExtraLarge;
    },
    imageUrl() {
      return this.device.imageURL || null;
    },
    status() {
      return this.device && this.device.status ? this.device && this.device.status : undefined;
    },
    operationalModeMessage() {
      return this.status && this.status.operationalMode ? this.status.operationalMode : false;
    },
    powerMessage() {
      if (!this.status || this.status.power === undefined) {
        return false;
      }
      return this.status.power ? 'On' : 'Off';
    },
    wifiMessage() {
      if (!this.status || this.status.wifiConnected === undefined) {
        return false;
      }
      return this.status.wifiConnected ? 'Connected' : 'Disconnected';
    },
    touMessage() {
      return this.status && this.status.tou ? this.status.tou : false;
    },
  },
};
</script>
