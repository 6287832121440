<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error" phonetext="For further help, please call us at"
      closepath="/yard-care-rebate" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 Verify Customer Information -->
      <section id="yard-care-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Customer Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m">Account: {{ currentAccount.nickname }}
                </div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
                  currentAccount.accountNumber
                }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                yard care rebate.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ customerName }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                yard care rebate.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ customerName }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Product Rebate Details -->
      <section id="yard-care-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Yard Care Equipment</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">Total Savings: ${{ totalSavings }}</div>
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="rebate-slap__inner-row">
                <div class="row-s">
                  <form data-vv-scope="page2" @submit.prevent="submitForm">
                    <fieldset class="gds-fieldset">
                      <label>
                        <span class="gds-input-field__label">How many yard care products did you purchase?</span>
                        <select @change="selectNumOfYardCareTools(numberOfYardCareTools)" v-model="numberOfYardCareTools"
                          v-validate="'required'" name="number of yard care tools" key="number of yard care tools"
                          class="eicproduct-input-field-l__select gds-input-field eicproduct-input-field__input gds-space-stack-l"
                          autofocus>
                          <option disabled value="undefined">Select number of yard care tools purchased</option>
                          <option :value="1">1</option>
                          <option :value="2">2</option>
                          <option :value="3">3</option>
                          <option :value="4">4</option>
                          <option :value="5">5</option>
                          <option :value="6">6</option>
                          <option :value="7">7</option>
                          <option :value="8">8</option>
                          <option :value="9">9</option>
                        </select>
                        <div v-if="errors.first('number of yard care tools', 'page2')"
                          class="eicproduct-validation-error">
                          {{ errors.first("number of yard care tools", "page2") }}</div>
                      </label>
                      <div v-for="(yardCareEquipment, i) in productSubmission.yardCareEquipment" :key="i"
                        class="gds-space-stack-l">
                        <div class="flex-item margin-right">
                          <div class="gds-space-stack-ml" style="display: flex; justify-content: space-between; height: 4rem; align-items:center;">
                            <h2 style="align-self: center; margin: 0;">Yard Product #{{ i + 1 }}</h2>
                            <button v-if="numberOfYardCareTools > 1" @click=" removeYardEquipment(i)"
                              class="gds-button gds-button-circle" style="margin: 3rem;">
                                <svg class="gds-icon">
                                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
                                </svg>
                                </button>
                          </div>
                          <legend class="gds-display-0 gds-space-stack-m">Please enter the info for each yard care
                            product
                          </legend>
                          <div>
                            <div class="row-s">
                              <form data-vv-scope="page2">
                                <fieldset class="gds-fieldset">
                                  <div>
                                    <label>
                                      <span class="gds-input-field__label">Product Purchased</span>
                                      <select 
                                        v-model="productSubmission.yardCareEquipment[i].productTypeName"
                                        v-validate="'required'" :name="`product type ${i}`"
                                        :data-vv-name="'product type ' + i"
                                        class="eicproduct-input-field-l__select gds-input-field gds-space-stack-m"
                                        autofocus>
                                        <option disabled :value="undefined">Select product type</option>
                                        <option v-for="(product, i) in yardCareProducts" :value="product.yardCareProductName"
                                          :key="product.yardCareProductName + i">{{ product.yardCareProductName }}</option>
                                      </select>
                                      <div v-if="errors.has(`product type ${i}`, 'page2')"
                                        class="eicproduct-validation-error">
                                        {{ errors.first(`product type ${i}`, 'page2').replace(/product type \d+/g,
                                          "product type") }}
                                      </div>
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <span class="gds-input-field__label">Make/Brand</span>
                                      <input v-model="productSubmission.yardCareEquipment[i].make"
                                        v-validate="'required|max:50'" :name="`brand ${i}`" key="brand"
                                        class="gds-input-field__input gds-space-stack-m" type="text" autocomplete="off"
                                        placeholder="Type Here..." />
                                      <div v-if="errors.first(`brand ${i}`, 'page2')" class="eicproduct-validation-error">
                                        {{ errors.first(`brand ${i}`, "page2").replace(/brand \d+/g, "brand") }}
                                      </div>
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <span class="gds-input-field__label">Model</span>
                                      <input v-model="productSubmission.yardCareEquipment[i].model"
                                        v-validate="'required|max:50'" :name="`model ${i}`" key="model"
                                        class="gds-input-field__input gds-space-stack-m" type="text" autocomplete="off"
                                        placeholder="Type Here..." />
                                      <div v-if="errors.first(`model ${i}`, 'page2')" class="eicproduct-validation-error">
                                        {{ errors.first(`model ${i}`, "page2").replace(/model \d+/g, "model") }}
                                      </div>
                                    </label>
                                  </div>
                                  <div>
                                    <label @click.prevent class="gds-input-field">
                                      <span class="gds-input-field__label">Purchase Date</span>
                                      <div class="eicproduct-purchasedate__date-box gds-space-stack-ml">
                                        <div class="my-account-billing-report__filtration-date-icon">
                                          <svg class="gds-icon">
                                            <use
                                              xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                                          </svg>
                                        </div>
                                        <datepicker ref="datePicker"
                                          v-model="productSubmission.yardCareEquipment[i].purchaseDate"
                                          :name="`purchase date ${i}`" key="purchase date" v-validate="'required'"
                                          format="MMMM d, yyyy" :typeable="true" placeholder="Select Date..."
                                          :disabled-dates="disableDates"
                                          input-class="export-data__date my-account-billing-report__filtration-date-text" />
                                      </div>
                                      <div v-if="errors.first(`purchase date ${i}`, 'page2')"
                                        class="eicproduct-validation-error">
                                        {{ errors.first(`purchase date ${i}`, "page2").replace(/purchase date \d+/g,
                                          "purchase date") }}
                                      </div>
                                    </label>
                                  </div>
                                  <div v-if="purchasedCommercialMower() &&
                                               productSubmission.yardCareEquipment[i].productTypeName === ELECTRIC_COMMERCIAL_LAWNMOWER">
                                    <div>
                                      <label class="gds-input-field">
                                        <span class="gds-input-field__label">Will the lawnmower be used for commercial
                                          purposes - e.g. landscaping or property management?</span>
                                        <div>
                                          <select
                                            v-model="productSubmission.yardCareEquipment[i].usedForCommercialPurposes"
                                            @change="onChangeUsedForCommercialPurpose($event, i)"
                                            class="eicproduct-input-field__select gds-space-stack-m"
                                            v-validate="'required'" name="Used For Commercial Purposes"
                                            key="Used For Commercial Purposes" autofocus>
                                            <option disabled select value=undefined>Select...</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                          </select>
                                        </div>
                                        <div v-if="errors.first('Used For Commercial Purposes', 'page2')"
                                          class="validation-error">{{
                                            errors.first("Used For Commercial Purposes", "page2")
                                          }}</div>
                                      </label>
                                    </div>
                                    <div
                                      v-if="productSubmission.yardCareEquipment[i].usedForCommercialPurposes === 'yes'">
                                      <div>
                                        <label class="gds-input-field">
                                          <span class="gds-input-field__label">How many days will the lawnmower
                                            be used in a typical year?</span>
                                          <div>
                                            <input v-model="productSubmission.yardCareEquipment[i].daysUsedPerYear"
                                              class="gds-input-field__input gds-space-stack-m"
                                              v-validate="'required|between:1,365'" name="Days Used per Year"
                                              key="Days Used per Year" autofocus>
                                          </div>
                                        </label>
                                        <div v-if="errors.first('Days Used per Year', 'page2')"
                                          class="eicproduct-validation-error" style="max-width:80%">{{ errors.first("Days Used per Year", "page2") }}
                                        </div>
                                      </div>
                                      <div>
                                        <label class="gds-input-field">
                                          <span class="gds-input-field__label">How many hours will the lawnmower
                                            be operated on a typical day?</span>
                                          <div>
                                            <input v-model="productSubmission.yardCareEquipment[i].hoursUsedPerDay"
                                              class="gds-input-field__input" v-validate="'required|between:1,24'"
                                              name="Hours Used per Day" key="Hours Used per Day" autofocus>
                                          </div>
                                        </label>
                                        <div v-if="errors.first('Hours Used per Day', 'page2')"
                                          class="eicproduct-validation-error" style="max-width:80%">{{ errors.first("Hours Used per Day", "page2") }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                  <ModalEICProducts modalName="informational" v-if="showInformationalModal" :close="closeModal"
                    :modalMessage="modalMessage" />
                </div>
                <button v-if="productSubmission.yardCareEquipment.length > 0" :disabled="eicMetaLoadState === 'loading'"
                  @click="finishPage('page2', 3);" class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 Upload Documents -->
      <section id="yard-care-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Upload Documents</div>
                <ModalEICProducts modalName="missingDocument" v-if="showMissingDocumentsModal" :close="closeModal"
                  modalTitle="You’re missing a required document" modalMessage="Please upload a copy of the following:"
                  :listItems="missingDocuments" />
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="`Your total file size is ${this.$options.filters.fileSizeFormat(totalFileSize)}. Please make sure the combined size of all uploaded files is less than ${this.$options.filters.fileSizeFormat(metadata.maxFileUploadSize)}.`" />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="uploadDocumentList.length > 1" class="gds-body-normal gds-space-inline-s">{{
                  `${this.uploadDocumentList.length}
                  Files Uploaded`}}</div>
                <div v-if="uploadDocumentList.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.uploadDocumentList.length} File Uploaded` }}</div>
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div>
                <div class="slat-title-wrapper">
                  <form>
                    <div id="fileUploader">
                      <File-Uploader :uploadDocumentList="uploadDocumentList" :docTypes="documentTypes"
                        @file-list-changed="updateFileList"></File-Uploader>
                    </div>
                  </form>
                </div>
                <button @click="finishPage('page3', 4)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 Contact & Mailing Address -->
      <section id="yard-care-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Contact & Mailing Address</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-s">Mailing Address: {{
                  productSubmission.rebate.rebateMailingAddress | addressFormat({ seperator: ", " })
                }}</div>
                <button @click="gotoPage(4)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page4">
                  <div class="gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Please make my check out to:</span>
                      <input v-model="productSubmission.rebate.rebatePayeeName" @input="$emit('touched', true)"
                        v-validate="'required'" name="rebate payee name" key="rebate payee name"
                        class="eicproduct-input-field gds-input-field__input" type="text" ref="focuser4" />
                      <div v-if="errors.first('rebate payee name', 'page4')" class="eicproduct-validation-error">{{
                        errors.first("rebate payee name", "page4")
                      }}</div>
                    </label>
                  </div>
                  <div class="gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field-label">Where should we send your rebate check?</span>
                    </label>
                  </div>
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Country</span>
                    <select v-model="productSubmission.rebate.rebateMailingAddress.country"
                      :class="productSubmission.rebate.rebateMailingAddress.country === 'USA' ? 'eicproduct-input-field__select-country-us gds-input-field' : productSubmission.rebate.rebateMailingAddress.country === 'CAN' ? 'eicproduct-input-field__select-country-ca gds-input-field' : 'eicproduct-input-field__select-s gds-input-field'"
                      autofocus>
                      <option :value="'USA'" selected
                        data-thumbnail="/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg">United States
                      </option>
                      <option value="CAN">Canada</option>
                      <option value="FOR">Foreign</option>
                    </select>
                    <div v-if="!productSubmission.rebate.rebateMailingAddress.country"
                      class="eicproduct-validation-error">
                      {{ errors.first("country", "page4") }}</div>
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Street Address</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street1" @input="$emit('touched', true)"
                      v-validate="'required'" name="street address" key="street address"
                      class="eicproduct-input-fsield gds-input-field__input" type="text" placeholder="Type Here..." />
                    <div v-if="errors.first('street address', 'page4')" class="validation-error">{{
                      errors.first("street address", "page4")
                    }}</div>
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Suite Apt</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street2" @input="$emit('touched', true)"
                      name="street 2" key="street 2" class="eicproduct-input-field gds-input-field__input" type="text"
                      placeholder="Type Here..." />
                  </label>
                  <br />
                  <br />
                  <div class="row-l">
                    <div class="flex-item-l">
                      <label class="gds-input-field-flex">
                        <span class="gds-input-field__label">City</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.city"
                          @input="$emit('touched', true)" v-validate="'required'" name="city" key="city"
                          class="gds-input-field__input" type="text" placeholder="Type Here..." />
                        <div v-if="errors.first('city', 'page4')" class="validation-error">{{ errors.first("city",
                          "page4")
                        }}</div>
                      </label>
                    </div>
                    <div class="flex-item-s gds-space-stack-m">
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">State</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select State...</option>
                          <option v-for="state of stateList" :key="state" :value="state">{{ state }}</option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("state", "page4") }}</div>
                      </label>
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">Province</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select Province...</option>
                          <option v-for="province of provinceList" :key="province" :value="province">{{ province }}
                          </option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("province", "page4") }}</div>
                      </label>
                    </div>
                    <div></div>
                    <div class="flex-item-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Zipcode</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.zip" @input="$emit('touched', true)"
                          v-validate="'required|numeric|length:5'" name="zipcode" key="zipcode"
                          class="gds-input-field__input eicproduct-mailing-zip" type="text" placeholder="Type Here..."
                          autocomplete="postal-code" />
                        <div v-if="errors.first('zipcode', 'page4')" class="validation-error">{{ errors.first("zipcode",
                          "page4")
                        }}</div>
                      </label>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="eicproduct-validation-error">{{ errors.first("zip", "page4") }}</div>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="eicproduct-validation-error">{{ errors.first("canadianZip", "page4") }}</div>
                    </div>
                  </div>
                  <div>
                    <br>
                    <div class="gds-input-field-label">How should we contact you if we need additional information to process
                      your rebate?
                    </div>
                    <br>
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Email Address</span>
                        <input v-model="productSubmission.rebate.confirmationEmailAddress" @input="$emit('touched', true)"
                          v-validate="'required|email'" name="email address" key="email address"
                          class="eicproduct-input-field gds-input-field__input" type="email" placeholder="Your email"
                          autocomplete="email" ref="email" />
                        <div v-if="errors.first('email address', 'page4')" class="validation-error">{{
                          errors.first("email address", "page4")
                        }}</div>
                      </label>
                    </div>
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Phone Number</span>
                      <div class="gds-space-stack-xl">
                        <the-mask v-model="productSubmission.rebate.contactPhoneNumber" :mask="'+1 (###) ###-####'"
                          @input.native="touched = true" v-validate="'required|min:10'" data-vv-validate-on="input"
                          :name="'phone number'" :key="'phone number'"
                          class="eicproduct-input-field gds-input-field__input" type="tel"
                          placeholder="Your phone number" />
                        <div v-if="errors.first('phone number', 'page4')" class="validation-error">{{
                          errors.first("phone number", "page4")
                        }}</div>
                      </div>
                    </label>
                    <div v-if="!isAlreadyEbillEnrolled">
                      <div class="gds-body-small gds-space-stack-l">
                        <span class="gds-display-1 gds-space-stack-s">Would you like to go paperless?</span>
                      </div>
                      <div class="gds-body-small gds-space-stack-l slat3-body">
                        You can reduce your carbon footprint even more,
                        by switching to paperless billing! Paperless monthly energy
                        statements cut carbon and clutter while saving trees.
                      </div>
                      <div class="gds-space-stack-ml">
                        <label class="gds-checkbox gds-checkbox-thin">
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEnroll" type="checkbox" name />
                          <span class="gds-checkbox__faux"></span>
                          <span class="gds-font-size-ml">
                            <b>Yes!</b> - Enroll me in paperless billing so I can reduce my carbon footprint even more.
                          </span>
                        </label>
                      </div>
                      <div v-if="productSubmission.programEnrollment.ebill.ebillEnroll" class="gds-space-stack-ml">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Account email address</span>
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEmailAddress"
                            v-validate="'required|email'" @input="$emit('touched', true)" name="account email address"
                            key="account email address" class="eicproduct-input-field gds-input-field__input" type="email"
                            placeholder="Your email" autocomplete="email" ref="email" />
                          <div v-if="errors.first('account email address', 'page4')" class="validation-error">{{
                            errors.first("account email address", "page4")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
                <button @click="finishPage('page4', 5)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 5 Review & Submit-->
      <section id="yard-care-slat-5" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>5</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div v-if="currentAccount.customerClass === RESIDENTIAL">
                  <div class="eicproducts-required-text gds-space-stack-m">
                    One more thing! To ensure all Vermonters benefit equitably from the transition to clean energy, we’re asking
                    program participants whether you would like to <span><b>voluntarily</b></span> share your household size and income. 
                    This helps develop better programs to meet the needs of customers. Your personal information is confidential.
                  </div>
                  <form data-vv-scope="page5">
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Are you willing to share information about your
                      household income?</span>
                    <div class="gds-position--relative">
                      <div class="gds-space-stack-l">
                        <select @change="onChangeWillShareIncome($event)"
                          v-model="productSubmission.rebate.householdIncome.willShareIncome"
                          class="eicproduct-input-field__select" v-validate="'required'"
                          name="household income response" key="household income response" autofocus>
                          <option value disabled select>Select...</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </label>
                  <div v-if="errors.first('household income response', 'page5')" class="validation-error">{{
                    errors.first("household income response", "page5")
                  }}</div>
                  <div
                    v-if="productSubmission.rebate.householdIncome.willShareIncome && productSubmission.rebate.householdIncome.willShareIncome.toLowerCase() === 'yes'"
                    class="row-s">
                    <div class="gds-space-stack-l">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Number Of Household Members</span>
                        <div>
                          <select @change="buildIncomeDropDown(); clearHouseholdIncome() "
                            v-model="productSubmission.rebate.householdIncome.numberInHousehold"
                            class="eicproduct-input-field__select" v-validate="'required'"
                            name="number in household" key="number in household" autofocus>
                            <option v-for="(option, index) of metadata.incomeIncentives" :key="index"
                              :value="option.numberInHousehold">{{ option.numberInHousehold }}
                            </option>
                            <option value disabled select>Select...</option>
                          </select>
                        </div>
                      </label>
                      <div v-if="errors.first('number in household', 'page5')" class="eicproduct-validation-error"
                        style="max-width:80%">{{ errors.first("number in household", "page5") }}
                      </div>
                    </div>
                    <label class="gds-input-field gds-space-stack-ml">
                      <span class="gds-input-field__label">Gross Household Income in {{ lastYear }}</span>
                      <select v-model="productSubmission.rebate.householdIncome.grossHouseholdIncomeRange"
                        class="eicproduct-input-field__select" autofocus v-validate="'required'"
                        name="gross household income" key="gross household income"
                        :disabled="!productSubmission.rebate.householdIncome.numberInHousehold ? true : false">
                        <option v-for="(option, index) of householdIncomeDropdown" :key="index"
                          :value="option.incomeAmount">{{ option.incomeOption }}
                        </option>
                      </select>
                      <div v-if="errors.first('gross household income', 'page5')"
                        class="eicproduct-validation-error">
                        {{ errors.first("gross household income", "page5") }}</div>
                    </label>
                  </div>
                  </form>
                </div>
                <div class="gds-display-00 gds-space-stack-s" ref="focuser5">Your rebate will be mailed to:</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebatePayeeName | titlecase()
                }}</div>
                <div class="gds-body-small gds-space-stack-l">{{ productSubmission.rebate.rebateMailingAddress |
                  addressFormat({ separator: ", " })
                }}</div>
                <div class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Your Rebate Information:</div>
                  <div class="gds-body-small gds-space-stack-s">Your incentive of ${{ this.totalSavings }}<br></div>
                  <div class="gds-body-small gds-space-stack-s"><br>For purchase of:</div>
                  <div v-for="(yardCareEquipment, i) in productSubmission.yardCareEquipment" :key="i"
                    class="gds-body-small gds-space-stack-s">
                    <span>{{ i + 1 }}. {{ yardCareEquipment.productTypeName }} </span>
                  </div>
                </div>
                <div class="gds-space-stack-m">
                    <label class="gds-checkbox gds-checkbox-thin">
                      <input v-model="regulatoryDataSharing" v-validate="'required'" type="checkbox"
                        key="regulatory data sharing" name="regulatory data sharing" />
                      <span class="gds-checkbox__faux"></span>
                      <span class="gds-font-size-ml regulatory-text1">
                        {{ REGULATORY_REBATE_SHARING_TEXT1  }}
                      </span>
                      <p class="gds-font-size-ml regulatory-text2">
                        {{ REGULATORY_REBATE_SHARING_TEXT2  }}
                      </p>
                      <div v-if="errors.first('regulatory data sharing', 'page5')"
                        class="eicproduct-validation-error">{{
                        errors.first("regulatory data sharing", "page5")
                        }}</div>
                    </label>
                  </div>
                  <button v-if="!pendingSubmit" 
                    @click="handleSubmit" 
                    type="submit" 
                    :disabled="pendingSubmit || !regulatoryDataSharing"
                    class="`eicproduct__method-next-btn gds-button gds-compact`">
                    Submit Request
                  </button>
                  <button v-if="pendingSubmit" 
                    class="gds-button [modifier class] gds-loading">
                    <span class="gds-button__text">Submit Request</span>
                    <figure class="gds-button__icon"></figure>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag } from "vee-validate";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import ModalEICProducts from "../ModalEICProducts.vue";
import FileUploader from "../FileUploader.vue";
import { ToServerDateTruncate } from "../../../utilities";
import { proofOfPurchase, w9, regulatoryRebateSharingText1, regulatoryRebateSharingText2 } from "../constants";
import { getConfigValue } from "../../../services/featureflags";


const date = new Date();
date.setDate(date.getDate() + 1);

export default {
  name: "yardCareRebateFlow",
  components: {
    FileUploader,
    Datepicker,
    ModalEICProducts,
  },
  data() {
    return {
      disableDates: {
        to: new Date(2022, 5, 1),
        from: date
      },
      lastYear: new Date().getFullYear() - 1,
      loadState: undefined,
      metadata: undefined,
      pendingSubmit: undefined,
      eicMetaLoadState: undefined,
      lastYear: new Date().getFullYear() - 1,
      touched: false,
      pendingSave: undefined,
      isAlreadyEbillEnrolled: undefined,
      productTypeName: undefined,
      yardCareProducts: undefined,
      hoursUsedPerDayPicklist: [],
      daysUsedPerYearPicklist: [],

      totalFileSize: undefined,
      maxFileLoadSize: undefined,

      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"],

      totalSavings: 0,
      householdIncomeDropdown: [],

      ELECTRIC_COMMERCIAL_LAWNMOWER: 'Electric Commercial Lawnmower',
      PROOF_OF_PURCHASE: proofOfPurchase,
      W9: w9,
      W9_DOLLAR_THRESHOLD: 50,
      COMMERCIAL: 'C',
      RESIDENTIAL: 'R',
      NO: 'no',
      regulatoryDataSharing: undefined,
      REGULATORY_REBATE_SHARING_TEXT1: undefined,
      REGULATORY_REBATE_SHARING_TEXT1_DEFAULT: regulatoryRebateSharingText1,
      REGULATORY_REBATE_SHARING_TEXT2: undefined,
      REGULATORY_REBATE_SHARING_TEXT2_DEFAULT: regulatoryRebateSharingText2,
      showDocumentChecklist: false,
      showMissingDocumentsModal: false,
      showFileLoadTooBigModal: false,
      showInformationalModal: false,
      modalLink: '',
      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      selectedDocumentType: undefined,
      missingDocuments: [],
      uploadDocumentList: [],
      numberOfYardCareTools: undefined,
      productTypeMappings: [
        { displayName: 'Electric Lawnmower', productType: 'electricLawnmower' },
        { displayName: 'Electric Trimmer', productType: 'electricTrimmer' },
        { displayName: 'Electric Leaf Blower', productType: 'electricLeafBlower' },
        { displayName: 'Electric Chainsaw', productType: 'electricChainsaw' },
        { displayName: 'Electric Yard Tractor', productType: 'electricYardTractor' },
        { displayName: 'Electric Commercial Lawnmower', productType: 'electricCommercialLawnmower' }
      ],

      productSubmission: {
        submissionType: "yardCareRebate",
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            street1: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined,
          },
          mailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          }
        },
        yardCareEquipment: [],
        w9Added: false,
        documentList: [],
        rebate: {
          rebatePayeeName: undefined,
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
          rebateDeliveryMethod: "Mailed Check",
          householdIncome: {
            willShareIncome: '',
            grossHouseholdIncomeRange: '',
            numberInHousehold: ''
          },
          rebateMailingAddress: {
            street1: undefined,
            street2: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined,
          },
          rebateMailingAddressOverride: false,
        },
        programEnrollment: {
          ebill: {
            ebillEnroll: undefined,
            ebillEmailAddress: undefined,
          },
        },
      },
    }
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    customerName() {
      if (this.currentAccount.lastName != null && this.currentAccount.firstName != null) {
        return this.toTitleCase(this.currentAccount.lastName + ', ' + this.currentAccount.firstName);
      } else {
        return this.toTitleCase(this.currentAccount.lastName);
      }
    }
  },
  async mounted() {

    this.$store.commit("setLastValidPage", 'page1');
    this.yardCareProducts = await this.buildYardCareProductList(this.currentAccount.customerClass);
    this.setRebateSubmissionAccount();
    // Kick off async fetches
    try {
      this.loadState = undefined;
      await Promise.all([
        this.REGULATORY_REBATE_SHARING_TEXT1 = await getConfigValue('TXT_RebateRegulatorySharingText1', this.REGULATORY_REBATE_SHARING_TEXT1_DEFAULT, null),
        this.REGULATORY_REBATE_SHARING_TEXT2 = await getConfigValue('TXT_RebateRegulatorySharingText2', this.REGULATORY_REBATE_SHARING_TEXT2_DEFAULT, null)
        ]);

      const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;

      if (localStorageProductSubmissionObj &&
        localStorageProductSubmissionObj.submissionType === 'yardCareRebate') {
        this.productSubmission = localStorageProductSubmissionObj;

        if (this.productSubmission.documentList.length > 0) {
          this.uploadDocumentList = this.productSubmission.documentList;
          this.clearUploadFileList();
          this.finishPage('page2', 3);
        }
        this.initializeYardCareRebate();
      } else {
        this.addYardEquipment();
      }

      this.numberOfYardCareTools = this.productSubmission.yardCareEquipment.length;
      await Promise.all([
        this.checkIfOnEBill(),
      ]);
      this.loadState = "complete";
    } catch (err) {
      DumpError("yard care rebate loggedin load error", err);
      console.dir(err);
      this.loadState = "error";
    }

    if (this.productSubmission.rebate.householdIncome.numberInHousehold) {
      this.metadata = await this.getEICMeta(this.productSubmission.submissionType, this.productSubmission.yardCareEquipment[0].purchaseDate);
      this.buildIncomeDropDown();
    }

    setTimeout(() => {
      this.slatFocus(1);
    }, 1000);

    setTimeout(() => {
      this.resetState();
    }, 1000 * 60 * 60 * 6); // flush local storage & reload page after 6 hours
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
    },
    isResidentialCustomer() {
      return this.currentAccount.customerClass === this.RESIDENTIAL;
    },
    isCommercialCustomer() {
      return this.currentAccount.customerClass === this.COMMERCIAL;
    },
    async initializeYardCareRebate() {
      if (this.productSubmission.yardCareEquipment[0].purchaseDate &&
        (this.metaData === null || this.metaData === undefined)) {
        this.metadata = await this.getEICMeta(this.productSubmission.submissionType, this.productSubmission.yardCareEquipment[0].purchaseDate);
        this.calculateTotalSavings();
      }
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    selectNumOfYardCareTools(value) {
      if (value < this.productSubmission.yardCareEquipment.length) {
        const diff = this.productSubmission.yardCareEquipment.length - value
        for (let i = 0; i < diff; i++) {
          this.productSubmission.yardCareEquipment.pop();
          this.uploadDocumentList.pop();
        }
      } else if (value > this.productSubmission.yardCareEquipment.length) {
        const diff = value - this.productSubmission.yardCareEquipment.length;
        for (let i = 0; i < diff; i++) {
          this.addYardEquipment();
        }
      } else if (value === this.productSubmission.yardCareEquipment.length) {
        for (let i = 0; i < value; i++) {
          this.uploadDocumentList.push(
            {
              UUID: this.productSubmission.yardCareEquipment[i].UUID,
              documentName: this.PROOF_OF_PURCHASE + " " + this.productSubmission.yardCareEquipment[i].UUID,
              error: undefined,
              file: null,
              fileName: null,
              fileSize: null,
            }
          );
        }
      }
    },
    addYardEquipment() {
      const UUID = this.generateUUID();
      const emptyYardToolObject = {
        productType: undefined,
        make: undefined,
        model: undefined,
        dateOfPurchase: undefined,
        newOrRetrofit: undefined,
        purchasePrice: undefined,
        dealerName: undefined,
        usedForCommercialPurposes: undefined,
        daysUsedPerYear: 0,
        hoursUsedPerDay: 0,
        UUID: UUID,
      };
      this.productSubmission.yardCareEquipment.push(emptyYardToolObject);

      this.uploadDocumentList.push({
          UUID: UUID,
          documentName: this.PROOF_OF_PURCHASE + " " + UUID,
          displayName: `Proof of Purchase for`,
          error: undefined,
          file: null,
          fileName: null,
          fileSize: null,
        });
    },
    removeYardEquipment(i) {
      for (let index = 0; index < this.uploadDocumentList.length; index++) {
        if (this.productSubmission.yardCareEquipment[i].UUID === this.uploadDocumentList[index].UUID) {
          this.uploadDocumentList.splice(index, 1);
        }
      }
      this.productSubmission.yardCareEquipment.splice(i, 1);
      this.numberOfYardCareTools = this.productSubmission.yardCareEquipment.length;
    },
    onChangeWillShareIncome(event) {
      this.productSubmission.rebate.householdIncome.numberInHousehold = undefined;
      this.clearHouseholdIncome();
    },
    clearHouseholdIncome() {
      this.productSubmission.rebate.householdIncome.grossHouseholdIncomeRange = undefined;
    },
    onChangeUsedForCommercialPurpose(evt, i) {
      if (evt.target.value.toLowerCase() === this.NO) {
        this.productSubmission.yardCareEquipment[i].daysUsedPerYear = undefined;
        this.productSubmission.yardCareEquipment[i].hoursUsedPerDay = undefined;
      }
    },
    isEligibleForCommercialMowerRebate() {
      const isEligible = this.productSubmission.yardCareEquipment.every(item => {
        if (item.productTypeName != this.ELECTRIC_COMMERCIAL_LAWNMOWER) {
          return true;
        }
        if (item.usedForCommercialPurposes && item.usedForCommercialPurposes.toLowerCase() === this.NO) {
          return false;
        }

        if (item.daysUsedPerYear && item.daysUsedPerYear > 0 &&
          item.hoursUsedPerDay && item.hoursUsedPerDay > 0) {
          const totalHoursUsed = item.daysUsedPerYear * item.hoursUsedPerDay;
          if (totalHoursUsed < this.metadata.electricCommercialLawnMowerHoursUseToQualify) {
            return false;
          }
        }
        return true;
      });
      return isEligible;
    },
    toTitleCase(str) {
      if(!str) { return str; }
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    calculateTotalSavings() {
      const rebateMapping = {
        'Electric Lawnmower': this.metadata.electricLawnmowerRebateAmount,
        'Electric Trimmer': this.metadata.electricTrimmerRebateAmount,
        'Electric Chainsaw': this.metadata.electricChainsawRebateAmount,
        'Electric Leaf Blower': this.metadata.electricLeafBlowerRebateAmount,
        'Electric Yard Tractor': this.metadata.electricYardTractorRebateAmount,
        'Electric Commercial Lawnmower': this.metadata.electricCommercialLawnmowerRebateAmount,
      };

      this.totalSavings = 0;
      let productsPurchased = 0;

      this.productSubmission.yardCareEquipment.forEach(item => {
        const rebateAmount = rebateMapping[item.productTypeName];
        if (rebateAmount) {
          this.totalSavings += rebateAmount;
          productsPurchased++;
        }
      });

      let numberOfBundleToCreate = Math.floor(productsPurchased / this.metadata.numberYardToolsToQualifyForBundle);
      for (let i = 0; i < numberOfBundleToCreate; i++) {
        this.totalSavings += this.metadata.yardCareBundleRebate;
      }
    },
    async checkIfOnEBill() {
      const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      if (status.billRouteInformation.isEBill) {
        this.isAlreadyEbillEnrolled = true;
      }
    },
    gotoPage(page) {
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },
    chooseAccount() {
      this.$router.push({ path: "/account" });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`yard-care-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },
    setRebateSubmissionAccount() {
      this.productSubmission.rebate.rebatePayeeName = [
        this.toTitleCase(this.currentAccount.firstName?.trim()),
        this.toTitleCase(this.currentAccount.lastName?.trim())
      ].filter(Boolean).join(' ');
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.rebate.rebateMailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.rebate.contactPhoneNumber = this.currentAccount.phone;
      this.productSubmission.rebate.confirmationEmailAddress = this.currentAccount.emailAddress;
    },
    populateUploadDocument() {
      let docIndex = 0;
      for (let i = 0; i < this.uploadDocumentList.length; i++) {
        if (this.totalSavings > this.W9_DOLLAR_THRESHOLD && !this.productSubmission.w9Added) {
            this.uploadDocumentList.unshift({
                documentName: 'w9',
                displayName: 'IRS W-9',
                error: undefined,
                filename: null,
                file: null
            });
            this.productSubmission.w9Added = true;
        } else if (this.totalSavings <= this.W9_DOLLAR_THRESHOLD && this.productSubmission.w9Added) {
            this.uploadDocumentList.shift();
            this.productSubmission.w9Added = false;
        }

        if (this.uploadDocumentList[i].documentName.includes(this.PROOF_OF_PURCHASE) &&
          docIndex < this.productSubmission.yardCareEquipment.length &&
          this.productSubmission.yardCareEquipment[docIndex].productTypeName &&
          this.productSubmission.yardCareEquipment[docIndex].productTypeName != '') {
          this.uploadDocumentList[i].displayName = `Proof of Purchase for ${this.productSubmission.yardCareEquipment[docIndex++].productTypeName.replace('Electric', '')}`;
        }
      }
      this.productSubmission.documentList = this.uploadDocumentList;
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      let currentPage = nextPage - 1;

      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            this.navigate(pageName, currentPage, nextPage);
            break;

          case "page2":
            this.processYardCareToolPageComplete(pageName, currentPage, nextPage);
            break;

          case "page3":
            this.processFileUploadPageComplete();
            this.clearAndSaveLocalStorage();
            //this.navigate(pageName, currentPage, nextPage);
            break;

          case "page4":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            this.clearAndSaveLocalStorage();
            this.navigate(pageName, currentPage, nextPage);
            break;

          case "page5":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            this.clearAndSaveLocalStorage();
            this.navigate(pageName, currentPage, nextPage);
            break;
        }
      }
    },
    clearAndSaveLocalStorage() {
      localStorage.removeItem("localProductSubmission")
      this.setWithExpiry("localProductSubmission", this.productSubmission, (1000 * 60 * 60 * 6)); // 6 hours
    },
    navigate(pageName, currentPage, nextPage) {
      if (this.errors.any(pageName)) {
          this.slatFocus(currentPage);
        } else {
          this.$store.commit("setLastValidPage", pageName);
          this.gotoPage(nextPage);
        }
    },
    async processYardCareToolPageComplete(pageName, currentPage, nextPage) {
      this.errors.clear(pageName);
      if (!this.errors) this.errors = new ErrorBag();

      const effectiveDate = ToServerDateTruncate(new Date(this.productSubmission.yardCareEquipment[0].purchaseDate));
      this.metadata = await this.getEICMeta(this.productSubmission.submissionType, effectiveDate);

      this.calculateTotalSavings();

      if (this.isResidentialCustomer()) {
        this.metadata.incomeIncentives.sort((a, b) => a.numberInHousehold - b.numberInHousehold);
        this.clearIncomeDropDown();
        this.buildIncomeDropDown();
        this.populateUploadDocument();
        nextPage = 3;
      } else if (this.purchasedCommercialMower() && !this.isEligibleForCommercialMowerRebate()) {
        nextPage = 2;
        this.showInformationalModal = true;
        this.modalMessage = `Unfortunately, you do not qualify for a commercial lawn mower rebate. In order to qualify,
                                the mower must be used for commercial purposes and operated for
                                    ${this.metadata.electricCommercialLawnMowerHoursUseToQualify} or more hours per year.`;
        this.modalLink = '';
        return;
      } else {
        this.populateUploadDocument();
        nextPage = 3;
      }

      this.clearAndSaveLocalStorage();
      this.navigate(pageName, currentPage, nextPage);
    },
    processFileUploadPageComplete() {
      this.totalFileSize = 0;
      this.missingDocuments.length = 0;
      let docIndex = 0;

      if (this.isFileSizeOverLimit(this.uploadDocumentList)) {
        this.showFileLoadTooBigModal = true;
        this.modalMessage = `Your total file size is ${this.totalFileSize}. Please make sure the file size is less than ${this.maxFileLoadSize}.`;
        return;
      }

      if (this.isRequiredFileMissing()) {
        this.modalMessage = "You’re missing a required document";
        this.showMissingDocumentsModal = true;
        for (let i = 0; i < this.uploadDocumentList.length; i++) {
          if (this.uploadDocumentList[i].file === null || this.uploadDocumentList[i].file === undefined) {
            if (this.uploadDocumentList[i].documentName === this.W9) {
              this.missingDocuments.push('IRS-W9');
            } else if (this.uploadDocumentList[i].documentName.includes(this.PROOF_OF_PURCHASE)) {
              this.missingDocuments.push(`Proof of Purchase for ${this.productSubmission.yardCareEquipment[docIndex++].productTypeName}`);
            }
          }
        }
        return;
      }
      this.navigate('page3', 3, 4);
    },
    isRequiredFileMissing() {
      for (let i = 0; i < this.uploadDocumentList.length; i++) {
        if (this.uploadDocumentList[i].file === null || this.uploadDocumentList[i].file === undefined) {
          return true;
        }
      }
      return false;
    },
    clearUploadFileList() {
      for (let i = 0; i < this.uploadDocumentList.length; i++) {
        this.uploadDocumentList[i].file = null;
        this.uploadDocumentList[i].fileName = null;
        this.uploadDocumentList[i].fileSize = null; 
      }
    },
    isFileSizeOverLimit(fileList) {
      this.totalFileSize = this.calculateTotalFileSize(fileList);
      return this.totalFileSize > this.metadata.maxFileUploadSize;
    },
    calculateTotalFileSize(filelist) {
      let file;
      let totalFileSize = 0;
      for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].file) {
          if (file != filelist[i].file) {
            totalFileSize += filelist[i].file.size;
          }
          file = filelist[i].file;
        }
      }
      return totalFileSize;
    },
    findMapping(str) {
      let foundProperty = null;
      this.productTypeMappings.forEach(mapping => {
        if (mapping.displayName === str) {
          foundProperty = mapping.productType;
        }
      });
      return foundProperty;
    },
    closeModal(modalName) {
      if (modalName === 'missingDocument') {
        this.showMissingDocumentsModal = false;
        this.gotoPage(3);
      }

      if (modalName === 'documentChecklist') {
        if (this.selectedDocumentTypes) {
          const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
          this.documentTypes = array;
        }
        this.selectedFile = undefined;
        this.showDocumentChecklist = false;
      }

      if (modalName === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(3);
      }

      if (modalName == 'informational') {
        this.showInformationalModal = false;
      }
    },
    clearIncomeDropDown() {
      if (this.householdIncomeDropdown.size > 0) {
        this.householdIncomeDropdown.size = 0;
      }
    },
    buildIncomeDropDown() {
      const numberInHousehold = this.productSubmission.rebate.householdIncome.numberInHousehold;
      if (this.metadata && this.metadata.incomeIncentives.length > 0) {
        const incomeIncentive = this.metadata.incomeIncentives.find(incomeIncentive => incomeIncentive.numberInHousehold === numberInHousehold);

        if (incomeIncentive) {
          const lowerRangeAmount = "$" + incomeIncentive.incomeLimit.toLocaleString() + " or less ";
          const upperRangeAmount = "Greater than $" + incomeIncentive.incomeLimit.toLocaleString();
          this.householdIncomeDropdown = [];
          this.householdIncomeDropdown.push({
            incomeOption: lowerRangeAmount,
            incomeAmount: lowerRangeAmount
          });
          this.householdIncomeDropdown.push({
            incomeOption: upperRangeAmount,
            incomeAmount: upperRangeAmount
          });
        }
      }
    },
    async handleSubmit() {
      if (await this.$validator.validateAll('page5')) {
        try {
          this.pendingSubmit = true;

          this.productSubmission.yardCareEquipment.forEach(item => {
            item.productType = this.findMapping(item.productTypeName);
          });

          // Filter file list so that duplicate files are not included in request
          const uniqueFiles = this.uploadDocumentList.filter((document, index, self) =>
            index === self.findIndex((d) => (
              d.file.name === document.file.name
            ))
          );

          const formdata = new FormData();
          formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));
          
          for (let i = 0; i < uniqueFiles.length; i++) {
            formdata.append('files', uniqueFiles[i].file);
          }

          localStorage.removeItem("localProductSubmission")
          await GMPAPI.SubmitYardCareRebate(this.productSubmission.account.accountNumber, formdata);
          this.$router.push({ path: "/complete" });
        } catch (err) {
          DumpError("Yard Care Rebate Submission error", err);
          if (err.response && err.response.data) {
            let code = err.response.data.status;
            if (err.response.data.message) {
              console.error(err.response.data.message);
              code = err.response.data.code;
              this.$router.push({ path: "/error/" + code });
            } else {
              this.$router.push({ path: "/error/" + code });
            }
          } else {
            this.$router.push({ path: "/error/" });
          }
        }
      }
    },
    async buildYardCareProductList(customerClass) {
      const yardCareProducts = await GMPAPI.GetYardCareProducts();
      const filterCriteria = customerClass === 'R' ? 'residential' : 'commercial';
      return yardCareProducts.filter(item => item.eligibleCustomerClass.some(customerClass => customerClass === filterCriteria));
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);

      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    },
    purchasedCommercialMower() {
      const purchasedCommercialMower = this.productSubmission.yardCareEquipment.some(item => {
        return item.productTypeName === 'Electric Commercial Lawnmower';
      });
      return purchasedCommercialMower;
    },
    updateFileList(evt) {
      this.uploadDocumentList = evt;
    },
    async getEICMeta(productType, purchaseDate) {
      try {
        this.eicMetaLoadState = 'loading';
        let response = await GMPAPI.GetEICMeta(productType, purchaseDate);
        this.eicMetaLoadState = 'complete';
        return response;
      } catch (err) {
        DumpError("Yard Care Rebate EIC Meta Read error", err);
        if (err.response && err.response.data) {
          let code = err.response.data.status;
          if (err.response.data.message) {
            console.error(err.response.data.message);
            code = err.response.data.code;
            this.$router.push({ path: "/error/" + code });
          } else {
            this.$router.push({ path: "/error/" + code });
          }
        } else {
          this.$router.push({ path: "/error/" });
        }
        this.eicMetaLoadState = 'error';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>