var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-device-details-summary" }, [
    _c(
      "section",
      {
        staticClass: "gds-flex-container gds-flex-container--stretch",
        class: _vm.isDesktopView
          ? ""
          : "gds-flex-container--left gds-flex-container--column"
      },
      [
        !_vm.isDesktopView
          ? _c(
              "router-link",
              {
                staticClass:
                  "gds-button gds-secondary gds-button-circle button-white-overlay",
                attrs: { to: "/devices" }
              },
              [
                _c("svg", { staticClass: "gds-icon black" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left"
                    }
                  })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class: _vm.isDesktopView
              ? "gds-flex-container gds-flex-container--bottom gds-space-inline-m devices-background-blue-gradient devices-border-radius devices-width-25 gradient-background"
              : "gds-flex-container gds-flex-container--bottom devices-background-blue-gradient devices-border-radius devices-width-100 gradient-background"
          },
          [
            _c("img", {
              class: "device-image",
              attrs: { src: _vm.imageUrl, alt: _vm.generateAltText(_vm.device) }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "gds-flex-container gds-flex-container--column gds-flex-container--top gds-flex-container--space-between devices-width-100 devices-padding-m"
          },
          [
            _c(
              "section",
              {
                staticClass: "gds-space-stack-m devices-width-100",
                class: _vm.isDesktopView
                  ? ""
                  : "gds-flex-container gds-flex-container--space-between gds-flex-container--wrap gds-flex-container--top"
              },
              [
                _c("div", { staticClass: "gds-space-inline-m" }, [
                  _c(
                    "h2",
                    { staticClass: "gds-font-medium gds-space-stack-m" },
                    [_vm._v(_vm._s(_vm.device.deviceName))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-text-grey gds-font-medium" }, [
                    _vm._v(_vm._s(_vm.device.deviceTypeDescription))
                  ])
                ]),
                _vm._v(" "),
                _vm.operationalModeMessage
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "gds-space-top-m devices-font-capitalize devices-background-darkgray devices-color-white devices-container-compact border-round"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.operationalModeMessage) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between devices-gap-2",
                class: _vm.isDesktopView
                  ? ""
                  : "gds-flex-container--wrap gds-flex-container--left"
              },
              [
                _vm.powerMessage
                  ? _c("div", { staticClass: "gds-flex-container" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-power.svg",
                          alt: "Power button icon"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "devices-padding-m" }, [
                        _c("div", { staticClass: "gds-font-medium" }, [
                          _vm._v(_vm._s(_vm.powerMessage))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-text-grey" }, [
                          _vm._v("Power")
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.wifiMessage
                  ? _c("div", { staticClass: "gds-flex-container" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-connection.svg",
                          alt: "WiFi connection icon"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "devices-padding-m" }, [
                        _c("div", { staticClass: "gds-font-medium" }, [
                          _vm._v(_vm._s(_vm.wifiMessage))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-text-grey" }, [
                          _vm._v("Wi-Fi")
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.touMessage
                  ? _c("div", { staticClass: "gds-flex-container" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-rate-status.svg",
                          alt: "Clock icon"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "devices-padding-m" }, [
                        _c("div", { staticClass: "gds-font-medium" }, [
                          _vm._v(_vm._s(_vm.touMessage))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-text-grey" }, [
                          _vm._v("Current Rate Status")
                        ])
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }