<template>
  <div class="gds-space-stack-l devices-max-width-my-account">
    <div
      v-if="config.formType == FORM_TYPES.ADD_DEVICE_BYOD"
      class="gds-space-stack-l gds-flex-container gds-flex-container--left devices-gap-2 devices-background-off-white devices-border-radius devices-padding-m"
    >
      <img
        class="gds-logo devices-border-radius devices-width-25"
        src="https://static.greenmountainpower.com/img/devices/device-image-with-background-battery-tesla-powerwall-2.png"
        alt="Tesla Powerwall"
      >
      <div>
        <div class="gds-font-demi gds-font-size-xl gds-space-stack-s">{{ toTitleCaseNoHyphens(config.formData.batteryBrandAndModel) }}</div>
        <div v-if="config.formData.batterySerialNumber" class="gds-font-book">Serial No. {{ config.formData.batterySerialNumber }}</div>
      </div>
    </div>
    <!-- START BYOD FIELDS -->
    <template v-if="config.formType == FORM_TYPES.ADD_DEVICE_BYOD">
      <SimpleKeyValueText
        v-if="config.formData.preferredEnrollment"
        label="Preferred Enrollment"
        :value="toTitleCaseNoHyphens(config.formData.preferredEnrollment)"
      />
      <SimpleKeyValueText
        v-if="config.formData.totalBatteryCapacity"
        label="Total battery capacity"
        :value="config.formData.totalBatteryCapacity + '%'"
      />
      <SimpleKeyValueText
        v-if="config.formData.batterySharePercentage"
        label="Battery share percentage"
        :value="config.formData.batterySharePercentage + '%'"
      />
      <SimpleKeyValueText
        v-if="config.formData.totalEnrolledCapacity"
        label="Total Enrolled Capacity"
        :value="config.formData.totalEnrolledCapacity"
      />
      <SimpleKeyValueText
        v-if="config.formData.estimatedIncentive"
        label="Estimated Incentive"
        :value="config.formData.estimatedIncentive"
      />
    </template>
    <!-- END BYOD FIELDS -->
    <!-- START ADD-NEW FIELDS -->
    <template v-if="config.formType == FORM_TYPES.ADD_DEVICE_NEW">
      <SimpleKeyValueText
        v-if="config.selectedDeviceTypeLabel"
        label="Selected Product"
        :value="config.selectedDeviceTypeLabel"
      />
      <SimpleKeyValueText
        v-if="config.formData.installer"
        label="Selected Installer"
        :value="config.formData.installer"
      />
      <SimpleKeyValueText
        v-if="config.formData.contactName"
        label="Contact Name"
        :value="config.formData.contactName"
      />
      <SimpleKeyValueText
        v-if="config.formData.contactPhone"
        label="Contact Phone"
        :value="config.formData.contactPhone"
      />
      <SimpleKeyValueText
        v-if="config.formData.contactEmail"
        label="Contact Email"
        :value="config.formData.contactEmail"
      />
      <div v-if="config.formData.batteryInterests && config.formData.batteryInterests.length" class="gds-space-stack-l">
        <div class="gds-font-demi gds-space-stack-s">
          <span v-if="config.formData.batteryInterests.length == 1">Reason</span>
          <span v-else>Reasons</span>
          <span>you’re interested in a home battery system</span>
        </div>
        <ul>
          <li
            v-for="(interest, index) in config.formData.batteryInterests"
            :key="index"
            class="gds-font-book"
          >
            {{ interest }}
          </li>
        </ul>
      </div>
    </template>
    <!-- END ADD-NEW FIELDS -->
    <!-- START BYOD & ADD NEW FIELDS -->
    <template v-if="config.formType == FORM_TYPES.ADD_DEVICE_BYOD || config.formType == FORM_TYPES.ADD_DEVICE_NEW">
      <SimpleKeyValueText
        v-if="config.formData.deviceType"
        label="Device Type"
        :value="toTitleCaseNoHyphens(config.formData.deviceType)"
      />
      <SimpleKeyValueText
        v-if="config.formData.batteryBrandAndModel"
        label="Battery brand and model"
        :value="toTitleCaseNoHyphens(config.formData.batteryBrandAndModel)"
      />
      <SimpleKeyValueText
        label="Solar installed at this location"
        :value="config.formData.installationHasSolar ? 'Yes' : 'No'"
      />
      <SimpleKeyValueText
        v-if="config.accountNumber"
        label="GMP Account Number"
        :value="config.accountNumber"
      />
      <div class="gds-space-stack-l">
        <div class="gds-font-demi gds-space-stack-s">Service Location</div>
        <template v-if="config.formData.installationLocation == INSTALLATION_LOCATION_KEYS.SOMEWHERE_ELSE">
          <div class="gds-font-book">TBD</div>
        </template>
        <template v-if="config.formData.installationLocation == INSTALLATION_LOCATION_KEYS.PRIMARY_RESIDENCE && config.primaryResidenceHtml">
          <div class="gds-font-book" v-html="config.primaryResidenceHtml" />
        </template>
      </div>
      <SimpleKeyValueText
        v-if="config.reviewAndSubmitMessage"
        :value="config.reviewAndSubmitMessage"
      />
    </template>
    <!-- END BYOD & ADD NEW FIELDS -->
    <!-- START SERVICE_REQUEST FIELDS -->
    <div v-if="config.formType == FORM_TYPES.SERVICE_REQUEST">
      <SelectedDeviceSummary :device="config.selectedDevice" />
      <SimpleKeyValueText
        v-if="config.formData.problemSummary"
        label="Summarize the issue you’re having"
        :value="config.formData.problemSummary"
      />
      <div class="gds-space-stack-s gds-font-demi gds-font-size-xl">Your Contact Information</div>
      <SimpleKeyValueText
        v-if="config.formData.contactPhone"
        label="Phone Number (10-Digit)"
        :value="config.formData.contactPhone"
      />
      <SimpleKeyValueText
        v-if="config.formData.contactEmail"
        label="Email Address"
        :value="config.formData.contactEmail"
      />
      <SimpleKeyValueText
        v-if="config.reviewAndSubmitMessage"
        :value="config.reviewAndSubmitMessage"
      />
    </div>
    <!-- END SERVICE_REQUEST FIELDS -->
  </div>
</template>

<script>

import SimpleKeyValueText from './SimpleKeyValueText.vue';
import SelectedDeviceSummary from './SelectedDeviceSummary.vue';
import { FORM_TYPES, INSTALLATION_LOCATION_KEYS } from '../../../constants';

export default {
  name: 'ReviewAndSubmit',
  components: { SimpleKeyValueText, SelectedDeviceSummary },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      FORM_TYPES,
      INSTALLATION_LOCATION_KEYS,
    };
  },
  methods: {
    toTitleCaseNoHyphens(str) {
      if (!str) { return ''; }

      const formattedString = str.replace(/-/g, ' ');

      return formattedString.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
      );
    },
  },
};
</script>
