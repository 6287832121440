var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "devices-service-request-link" }, [
    _c("div", { staticClass: "devices-max-width-600" }, [
      _c("h4", { staticClass: "gds-font-medium gds-space-stack-ml" }, [
        _vm._v("Request Service")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "devices-gap-1",
          class: { "gds-flex-container": _vm.isDesktopView }
        },
        [
          _c("img", {
            staticClass: "devices-service-request-link__image",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-installer-van.svg",
              alt: "Service Truck"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gds-space-stack-m gds-font-book gds-font-size-m" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/devices/service-request" } }, [
                _c(
                  "button",
                  { staticClass: "devices-service-request-link__button" },
                  [_vm._v("Request Service")]
                )
              ])
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          If you’re having issues with a device, we’re here to help. Click below\n          or contact us at "
      ),
      _c("a", { attrs: { href: "tel:8888354672" } }, [
        _vm._v("(888) 835-4672")
      ]),
      _vm._v(".\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }