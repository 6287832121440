var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.title
      ? _c("h4", { staticClass: "gds-font-medium gds-space-stack-ml" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "impact-card-list" },
      _vm._l(_vm.impacts, function(impact) {
        return _c("ImpactCard", { key: impact.key, attrs: { impact: impact } })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }